import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import { Map, GoogleApiWrapper, Marker, InfoWindow, Polygon } from 'google-maps-react';

import yellow_marker from '../assets/yellow_marker.png';
import green_marker from '../assets/green_marker.png';
import red_marker from '../assets/red_marker.png';

import logo from '../assets/logo_kprocessos.png';

//import map_Rio from '../assets/citities/map_Rio.png';

import polygonCoords_Paulo from './utils/polygonPaulo';

import Header from '../components/Header';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Visibility from '@material-ui/icons/Visibility';
//import Table from '../components/Table';
import Button from '@material-ui/core/Button';
//import ResponsiveContainer from '@bit/recharts.recharts.responsive-container';
import Container from '@material-ui/core/Container';
import FooterHome from '../components/FooterHome';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import Edit from '@material-ui/icons/Edit';
import Share from '@material-ui/icons/Share';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import {CopyToClipboard} from 'react-copy-to-clipboard';

let columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'protocol',
    headerName: 'Protocolo',
    width: 150,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Protocolo '}
        <span role="img" aria-label="enjoy">
          📄
        </span>
      </strong>
    ),
  },
  {
    field: 'process',
    headerName: 'Nº do Processo',
    width: 170,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Nº do Processo '}
        <span role="img" aria-label="enjoy">
          ♎
        </span>
      </strong>
    ),
  },
  {
    field: 'secretary',
    headerName: 'Secretaria',
    width: 250,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Secretaria '}
        <span role="img" aria-label="enjoy">
          🏛️
        </span>
      </strong>
    ),
  },
  {
    field: 'subcategory',
    headerName: 'Subcategoria',
    width: 330,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Subcategoria '}
        <span role="img" aria-label="enjoy">
          🏷️
        </span>
      </strong>
    ),
  },
  {
    field: 'city',
    headerName: 'Cidade',
    width: 200,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Cidade '}
        <span role="img" aria-label="enjoy">
          🏙️
        </span>
      </strong>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Status '}
        <span role="img" aria-label="enjoy">
          ✔️
        </span>
      </strong>
    ),
  },
  {
    field: 'actions',
    headerName: 'Ações',
    renderCell: () => (
      <Actions />
    ),
    sortable: false,
    width: 100,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    disableColumnMenu: true,
    disableReorder: true,
  },
];

let rows = [];

let rowsArray = [];

let process = '';
let user = '';

let selected_city = 'paulo';

export class Actions extends Component {

  state = {
    openDialog: false,
    status: '1',
    copied: false,

    page_rows: [],
  }

  handleSee = async () => {

    setTimeout(() => {
      window.open(`${apiString}/process?process_id=${process}&user=${user}&selected_city=${selected_city}`, '_blank'); //enviar selected_city para process
    }, 1500);

  }

  handleShare = async () => {

    this.setState({ copied: true });

  }

  handleEdit = async () => {
    await this.setState({ openDialog: true });
    //let 
    //alert(JSON.stringify(rowsArray[0]));
  }

  handleDelete = async () => {
    
  }

  onStatusChange = async () => {

    try {
      let resp = await api.get('/updateprocess', {
        params: {
          process_id: process,
          status: this.state.status,
          selected_city: 'paulo'
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('edited')) {

            window.location.reload();

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os dados do usuário. ' + err);
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return(
      <div>
        <IconButton
          color="inherit"
          size="small"
          aria-label="see"
          onClick={this.handleSee}
        >
          <Visibility fontSize="small" />
        </IconButton>

        <CopyToClipboard text={`${apiString}/process?process_id=${process}&user=${user}&selected_city=${selected_city}`}>
          <IconButton
            color="inherit"
            size="small"
            aria-label="share"
            onClick={this.handleShare}
          >
            <Share fontSize="small" />
          </IconButton>
        </CopyToClipboard>

        <IconButton
          color="inherit"
          size="small"
          aria-label="edit"
          onClick={this.handleEdit}
        >
          <Edit fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="delete"
          onClick={this.handleDelete}
        >
          <Delete fontSize="small" />
        </IconButton>

        <Dialog open={this.state.openDialog} onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Alteração</DialogTitle>
          <DialogContent>
            <DialogContentText color="#fff">
              Selecione ou preencha os campos que deseja alterar.
            </DialogContentText>

            <FormControl variant="outlined" fullWidth style={{ marginBottom: 20 }}>
              <InputLabel htmlFor="outlined-theme-native-simple">
                Status
              </InputLabel>
              <Select
                autoFocus
                native
                value={this.state.status}
                onChange={this.handleChange}
                label="Status"
                inputProps={{
                  name: 'status',
                  id: 'outlined-status-native-simple',
                }}
              >
                <option value="1">Pendente</option>
                <option value="2">Regularizado</option>
                <option value="3">Negado</option>
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog: false })} style={{ color: '#fede5a' }}>
              Cancelar
            </Button>
            <Button onClick={async () => {
              this.setState({ openDialog: false });
              this.onStatusChange();
            }} style={{ color: '#fede5a' }}>
              Alterar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

let users = [];

export class HomePaulo extends Component {

  state = {
    user_id: '',
    login: '',
    option: '',
    selected_city: '',
    secretary: '',
    secretary_id: '',

    process_id: '',
    protocol: '',
    subcategory: '',

    zoom: 12,
    mobilezoom: 11,
    latitude: '',
    longitude: '',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    places: [],

    cpf_cnpj: '',
    name: '',
    email: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',

    new_lat: '',
    new_lng: '',

    expanded: false,
    openDialog: false,

    renderTable: false,

    url: null,

    search: '',

    polygonCoords_RioGeral: [],

    page_rows: [],

    row: true
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
      await this.setState({ name: this.props.location.state.fields.name.split('"').join('') });
      await this.setState({ secretary: this.props.location.state.fields.secretary.split('"').join('') });
      await this.setState({ secretary_id: this.props.location.state.fields.secretary_id });
      await this.setState({ user_id: this.props.location.state.fields.user_id });
    } else {
      const idx = document.URL.indexOf('?');

      var params = [];
      if (idx !== -1) {
        var pairs = document.URL.substring(idx + 1, document.URL.length).split('&');
        //alert(pairs);
        for (var i = 0; i < pairs.length; i++) {
          let nameVal = pairs[i].split(',');
          nameVal = pairs[i].replace('login=', '');
          nameVal = nameVal.replace('option=', '');
          nameVal = nameVal.replace('selected_city=', '');
          nameVal = nameVal.replace('name=', '');
          nameVal = nameVal.replace('secretary=', '');
          nameVal = nameVal.replace('secretary_id=', '');
          nameVal = nameVal.replace('user_id=', '');
          params.push(nameVal);
        }
      }

      await this.setState({ login: decodeURI(params[0]) });
      await this.setState({ option: decodeURI(params[1]) });
      await this.setState({ selected_city: decodeURI(params[2]) });
      await this.setState({ name: decodeURI(params[3]) });
      await this.setState({ secretary: decodeURI(params[4]) });
      await this.setState({ secretary_id: decodeURI(params[5]) });
      await this.setState({ user_id: decodeURI(params[6]) });
    }

    user = this.state.user_id;
    selected_city = this.state.selected_city;

    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.setState({ row: false });
    } else {
      this.setState({ row: true });
    }

    await this.setState({ latitude: -22.549731 });
    await this.setState({ longitude: -43.677680 });

    selected_city = this.state.selected_city;

    try {
      let resp = await api.get('/process', {
        params: {
          secretary_id: this.state.secretary_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          //alert(JSON.stringify(resp.data));
          if (JSON.stringify(resp.data).includes('protocol')) {
            users = resp.data;

            for (let i = 0; i < resp.data.length; i++) {
              let status = '';
              if (resp.data[i].status === 1) {
                status = '🟡 Pendente';
              } else {
                if (resp.data[i].status === 2) {
                  status = '🟢 Regularizado';
                } else {
                  if (resp.data[i].status === 3) {
                    status = '🔴 Negado';
                  }
                }
              }
              let aux = {
                id: `${resp.data[i].id}`, 
                protocol: `${resp.data[i].protocol}`, 
                process: `${resp.data[i].process_number}`, 
                secretary: `${resp.data[i].secretary_name}`, 
                subcategory: `${resp.data[i].subcategory_name}`, 
                city: `${resp.data[i].city} - ${resp.data[i].state}`,
                status: status
              };
              rows = Object.assign([], rows);
              rows.push(aux);
            }

            this.setState({ page_rows: rows });

            this.setState({ places: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

    this.setState({ page_rows: rows });

  }

  handleRegular = async () => {
    try {
      let resp = await api.get('/regularlocations', {
        params: {
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            await this.setState({ places: resp.data });

          } else {
            await this.setState({ places: resp.data });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

  }

  handleNegattive = async () => {
    try {
      let resp = await api.get('/negattivelocations', {
        params: {
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            await this.setState({ places: resp.data });

          } else {
            await this.setState({ places: resp.data });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

  }

  handlePendence = async () => {
    try {
      let resp = await api.get('/pendencelocations', {
        params: {
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            await this.setState({ places: resp.data });

          } else {
            await this.setState({ places: resp.data });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

  }

  handleChangePanel = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  onMarkerClick = (props, marker) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  }

  handleChange = async e => {
    await this.setState({ [e.target.name]: e.target.value });

    if (this.state.search.toUpperCase() === 'barra da tijuca'.toUpperCase()) {
      this.setState({ latitude: -22.99561048068859 }); //-22.99561048068859, -43.37167231281911
      this.setState({ longitude: -43.37167231281911 });
      this.setState({ zoom: 13 });
    } else {
      if (this.state.search.toUpperCase() === 'recreio'.toUpperCase()) {
        this.setState({ latitude: -23.013325217831405 }); //-23.013325217831405, -43.47353862101815
        this.setState({ longitude: -43.47353862101815 });
        this.setState({ zoom: 13 });
      } else {
        if (this.state.search.toUpperCase() === 'taquara'.toUpperCase()) {
          this.setState({ latitude: -22.92411592052944 }); //-22.92411592052944, -43.383641882969314
          this.setState({ longitude: -43.383641882969314 });
          this.setState({ zoom: 14 });
        } else {
          if (this.state.search.toUpperCase() === 'campo grande'.toUpperCase()) {
            this.setState({ latitude: -22.895650292382275 }); //-22.895650292382275, -43.5580595839595
            this.setState({ longitude: -43.5580595839595 });
            this.setState({ zoom: 12 });
          } else {
            if (this.state.search.toUpperCase() === 'méier'.toUpperCase()) {
              this.setState({ latitude: -22.902734628806304 }); //-22.902734628806304, -43.28060405942685
              this.setState({ longitude: -43.28060405942685 });
              this.setState({ zoom: 15 });
            } else {
              if (this.state.search.toUpperCase() === 'santa cruz'.toUpperCase()) {
                this.setState({ latitude: -22.915834986913296 }); //-22.915834986913296, -43.70211683723845
                this.setState({ longitude: -43.70211683723845 });
                this.setState({ zoom: 13 });
              } else {
                if (this.state.search.toUpperCase() === 'madureira'.toUpperCase()) {
                  this.setState({ latitude: -22.872239178670586 }); //-22.872239178670586, -43.33736988480521
                  this.setState({ longitude: -43.33736988480521 });
                  this.setState({ zoom: 15 });
                } else {
                  this.setState({ latitude: -22.931987594567158 });
                  this.setState({ longitude: -43.44911393720455 });
                  this.setState({ zoom: 11 });
                }
              }
            }
          }
        }
      }
    }
  }

  render() {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        margin: -10,
      }}
      >

        <Header
          history={this.props.history}
          login={this.state.login}
          option={this.state.option}
          selected_city={this.state.selected_city}
          name={this.state.name}
          secretary={this.state.secretary}
          secretary_id={this.state.secretary_id}
          user_id={this.state.user_id}
          {...this.props}
        />

        <Box sx={{
          backgroundColor: '#454545',
        }}
        >

          {this.state.row ? (
            <div style={styles.directionRow}>
              <Box
                sx={{
                  width: '50%',
                  height: window.innerHeight,
                  backgroundColor: '#454545',
                }}
              >

                <Typography style={styles.text}>
                  Bem vindo {this.state.name} ({this.state.secretary})
                </Typography>

                <Container maxWidth="lg" style={{ marginLeft: 15 }}>
                  <TextField
                    style={styles.text2}
                    id="search"
                    name="search"
                    value={this.state.search}
                    label="Buscar localidade"
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />

                  <DataGrid
                    style={{ height: 500 }}
                    rows={this.state.page_rows}
                    columns={columns}
                    pageSize={7}
                    rowsPerPageOptions={[7]}
                    disableSelectionOnClick
                    localeText={ptBR.props.MuiDataGrid.localeText}
                    onCellClick={async (row) => {
                      rowsArray = [];
                      await rowsArray.push(row);
                      process = '';
                      process = rowsArray[0].row.id;
                    }}
                  />
                </Container>

              </Box>

              <Box
                sx={{
                  backgroundColor: '#454545',
                }}
              >

                <div>
                  <Map
                    key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                    google={this.props.google}
                    zoom={this.state.zoom}
                    style={styles.map}
                    mapType="hybrid"
                    center={{
                      lat: this.state.latitude,
                      lng: this.state.longitude
                    }}
                    onClick={this.onMapClicked}
                  >


                    {<Polygon
                      paths={polygonCoords_Paulo}
                      strokeColor="#fede5a"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#fede5a"
                      fillOpacity={0.2}
                    />}

                    {/*<Polygon
                      paths={polygonCoords_Barra_da_Tijuca}
                      strokeColor="#800000"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#fff"
                      fillOpacity={0.2}
                    />*/}

                    {this.state.places.map((item) =>
                      <Marker
                        icon={{
                          url: (item.status === 1) ? yellow_marker : (item.status === 2) ? green_marker : red_marker,
                          scaledSize: new window.google.maps.Size(32, 40)
                        }}
                        position={{
                          lat: item.latitude,
                          lng: item.longitude
                        }}
                        onClick={async (props, marker) => {
                          this.setState({ process_id: item.id });
                          this.setState({ protocol: item.protocol });
                          this.setState({ subcategory: item.subcategory_name });
                          this.setState({ street: item.street });
                          this.setState({ number: item.number });
                          this.setState({ district: item.district });
                          this.setState({ city: item.city });
                          this.setState({ state: item.state });
                          this.setState({ new_lat: item.latitude });
                          this.setState({ new_lng: item.longitude });
                          this.onMarkerClick(props, marker);
                        }}
                        name={item.name}
                      />
                    )}

                    <InfoWindow
                      marker={this.state.activeMarker}
                      visible={this.state.showingInfoWindow}
                    >

                      <a
                        href={`${apiString}/process?process_id=${this.state.process_id}&user=${this.state.user_id}&selected_city=${this.state.selected_city}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          fullWidth
                          variant="text"
                          color="primary"
                          style={{ textTransform: 'none' }}
                        >
                          <Typography variant="h6" color="primary" align="left" style={{ maxWidth: 250 }}>
                            {`${this.state.protocol}`}
                          </Typography>
                        </Button>
                      </a>
                      <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                        {`${this.state.subcategory}`}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                        {`${this.state.street}, ${this.state.number} - ${this.state.district}, ${this.state.city} - ${this.state.state}`}
                      </Typography>

                    </InfoWindow>

                  </Map>
                </div>

              </Box>
            </div>
          ) : (
            <div style={styles.directionColumn}>

              <Box
                style={{
                  width: window.innerWidth - 30,
                  height: window.innerHeight - 370,
                  backgroundColor: '#454545',
                  marginTop: 10,
                }}
              >

                <Map
                  key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                  google={this.props.google}
                  zoom={this.state.mobilezoom}
                  style={styles.map2}
                  mapType="hybrid"
                  center={{
                    lat: this.state.latitude,
                    lng: this.state.longitude
                  }}
                  onClick={this.onMapClicked}
                >


                  {<Polygon
                    paths={polygonCoords_Paulo}
                    strokeColor="#fede5a"
                    strokeOpacity={0.5}
                    strokeWeight={2}
                    fillColor="#fede5a"
                    fillOpacity={0.2}
                  />}

                  {/*<Polygon
                      paths={polygonCoords_Barra_da_Tijuca}
                      strokeColor="#800000"
                      strokeOpacity={0.5}
                      strokeWeight={2}
                      fillColor="#fff"
                      fillOpacity={0.2}
                    />*/}

                    {this.state.places.map((item) =>
                      <Marker
                        icon={{
                          url: (item.status === 1) ? yellow_marker : (item.status === 2) ? green_marker : red_marker,
                          scaledSize: new window.google.maps.Size(32, 40)
                        }}
                        position={{
                          lat: item.latitude,
                          lng: item.longitude
                        }}
                        onClick={async (props, marker) => {
                          this.setState({ protocol: item.protocol });
                          this.setState({ subcategory: item.subcategory_name });
                          this.setState({ street: item.street });
                          this.setState({ number: item.number });
                          this.setState({ district: item.district });
                          this.setState({ city: item.city });
                          this.setState({ state: item.state });
                          this.setState({ new_lat: item.latitude });
                          this.setState({ new_lng: item.longitude });
                          this.onMarkerClick(props, marker);
                        }}
                        name={item.name}
                      />
                    )}

                    <InfoWindow
                      marker={this.state.activeMarker}
                      visible={this.state.showingInfoWindow}
                    >

                      <a href={`${apiString}/process?process_id=${this.state.process_id}`} target="_blank" rel="noreferrer">
                        <Button
                          fullWidth
                          variant="text"
                          color="primary"
                          style={{ textTransform: 'none' }}
                        >
                          <Typography variant="h6" color="primary" align="left" style={{ maxWidth: 250 }}>
                            {`${this.state.protocol}`}
                          </Typography>
                        </Button>
                      </a>
                      <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                        {`${this.state.subcategory}`}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" align="left" style={{ maxWidth: 250 }}>
                        {`${this.state.street}, ${this.state.number} - ${this.state.district}, ${this.state.city} - ${this.state.state}`}
                      </Typography>

                    </InfoWindow>

                </Map>
              </Box>

              <Box
                style={{
                  width: window.innerWidth - 30,
                  height: window.innerHeight + 150,
                  backgroundColor: '#454545',
                }}
              >

                <Typography style={styles.text}>
                  Bem vindo {this.state.name} ({this.state.secretary})
                </Typography>

                <Container maxWidth="lg" style={{ marginLeft: 15 }}>
                  <TextField
                    style={styles.text2}
                    id="search"
                    name="search"
                    value={this.state.search}
                    label="Buscar localidade"
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />

                    <DataGrid
                      style={{ height: 500 }}
                      rows={this.state.page_rows}
                      columns={columns}
                      pageSize={7}
                      rowsPerPageOptions={[7]}
                      disableSelectionOnClick
                      localeText={ptBR.props.MuiDataGrid.localeText}
                      onCellClick={async (row) => {
                        await rowsArray.push(row);
                        process = rowsArray[0].row.process;
                        selected_city = rowsArray[0].row.address;
                      }}
                    />
                </Container>

              </Box>

            </div>
          )}

        </Box>

        <FooterHome />

      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0',
  language: 'pt-BR'
})(HomePaulo)

let styles = {
  html: {
    backgroundColor: '#454545',
    margin: -8
  },

  directionRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },

  directionColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },

  text: {
    margin: 20,
    color: '#fff'
  },

  text2: {
    width: '100%',
    marginBottom: 20,
    color: '#fff'
  },

  accordionDiv: {
    marginTop: 10,
  },

  accordion: {
    width: 350,
    backgroundColor: '#048138',
  },

  list: {
    backgroundColor: '#343434',
    width: 315,
  },

  map: {
    width: '45%',
    height: '70%',
    marginLeft: 10,
    marginTop: 10,
    alignSelf: 'flex-start',
  },

  map2: {
    width: '95%',
    height: '50%',
    marginLeft: 12,
  },

  bt2: {
    margin: 10,
    width: '96.7%',
    textTransform: 'none',
    backgroundColor: '#343434',
    color: '#fff'
  },

  examples: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#454545',
    margin: 10,
    marginTop: 30,
  },

  href: {
    margin: 10,
    color: '#fff'
  },

  avatar: {
    alignSelf: 'center',
    width: 370,
    height: 80,
    margin: 10,
  },

};