import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import Header from '../components/Header';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
//import TreeView2 from '../components/TreeView2';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FooterHome from '../components/FooterHome';
import Add from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Tree } from 'rsuite';
import { styled } from '@material-ui/core';

export default class Subcategories extends Component {

  state = {
    user_id: '',
    login: '',
    option: '',
    selected_city: 'rios',
    secretary: '',
    secretary_id: '',

    subcategory: '',
    subcategory_select: 'select',
    subcategories: [],
    parents: [],

    zoom: 12,
    mobilezoom: 11,
    latitude: '',
    longitude: '',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    places: [],

    cpf_cnpj: '',
    name: '',
    email: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',

    new_lat: '',
    new_lng: '',

    expanded: false,
    openDialog: false,

    renderTable: false,

    row: true,

    data: [],
    children: [],

    open: false,
    open_error: false,
    msg: '',
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
      await this.setState({ name: this.props.location.state.fields.name.split('"').join('') });
      await this.setState({ secretary: this.props.location.state.fields.secretary.split('"').join('') });
      await this.setState({ secretary_id: this.props.location.state.fields.secretary_id });
      await this.setState({ user_id: this.props.location.state.fields.user_id });
    } else {
      const idx = document.URL.indexOf('?');

      var params = [];
      if (idx !== -1) {
        var pairs = document.URL.substring(idx + 1, document.URL.length).split('&');
        //alert(pairs);
        for (var i = 0; i < pairs.length; i++) {
          let nameVal = pairs[i].split(',');
          nameVal = pairs[i].replace('login=', '');
          nameVal = nameVal.replace('option=', '');
          nameVal = nameVal.replace('selected_city=', '');
          nameVal = nameVal.replace('name=', '');
          nameVal = nameVal.replace('secretary=', '');
          nameVal = nameVal.replace('secretary_id=', '');
          nameVal = nameVal.replace('user_id=', '');
          params.push(nameVal);
        }
      }

      await this.setState({ login: decodeURI(params[0]) });
      await this.setState({ option: decodeURI(params[1]) });
      await this.setState({ selected_city: decodeURI(params[2]) });
      await this.setState({ name: decodeURI(params[3]) });
      await this.setState({ secretary: decodeURI(params[4]) });
      await this.setState({ secretary_id: decodeURI(params[5]) });
      await this.setState({ user_id: decodeURI(params[6]) });
    }

    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.setState({ row: false });
    } else {
      this.setState({ row: true });
    }

    try {
      let resp = await api.get('/subcategories2', {
        params: {
          secretary_id: this.state.secretary_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ subcategories: resp.data });

          } else {
            this.setState({ msg: 'Nenhuma subcategoria cadastrada.' });
            this.setState({ open_error: true });
            this.setState({ open: false });
          }
        }
      }
    } catch (err) {
      this.setState({ msg: 'Não foi possível buscar as subcategorias. ' + err });
      this.setState({ open_error: true });
      this.setState({ open: false });
    }

    try {
      let resp = await api.get('/parents', {
        params: {
          secretary_id: this.state.secretary_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ parents: resp.data });

          } else {
            this.setState({ msg: 'Nenhuma subcategoria cadastrada.' });
            this.setState({ open_error: true });
            this.setState({ open: false });
          }
        }
      }
    } catch (err) {
      this.setState({ msg: 'Não foi possível buscar as subcategorias. ' + err });
      this.setState({ open_error: true });
      this.setState({ open: false });
    }

    let data = this.state.parents.map((item) => {
      return {
        label: item.name,
        value: `${item.id}`,
        children: []
      }
    })

    this.setState({ data });

  }

  handleChildren = async (child_id) => {

    try {
      let resp = await api.get('/specificsubcategories', {
        params: {
          sub_id: child_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ children: [] });
  
            for(let i = 0; i < resp.data.length; i++) {
              this.state.children.push({
                label: resp.data[i].name,
                value: `${resp.data[i].id}`,
                children: [],
              });
            }

            this.setState({ children: this.state.children });
  
          } else {
            this.state.children = [];
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar as subcategorias.  ' + err);
    }
  
  }

  handleSubmit = async (e) => {

    e.preventDefault();

    const data = new FormData();

    data.append('secretary_id', this.state.secretary_id);
    data.append('sub_id', this.state.subcategory_select);
    data.append('name', this.state.subcategory);
    data.append('selected_city', this.state.selected_city);

    try {
      let resp = await api.post('/subcategory', data);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('registred')) {

            this.setState({ msg: 'Subcategoria cadastrada com sucesso.' });
            this.setState({ open_error: false });
            this.setState({ open: true });
            this.componentDidMount();

          } else {
            this.setState({ msg: resp.data });
            this.setState({ open_error: true });
            this.setState({ open: false });
          }
        }
      }
    } catch (err) {
      this.setState({ msg: 'Não foi possível incluir esta subcategoria ' + err });
      this.setState({ open_error: true });
      this.setState({ open: false });
    }

  }

  handleChange = async e => {
    await this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        margin: -10,
      }}
      >

        <Header
          history={this.props.history}
          login={this.state.login}
          option={this.state.option}
          selected_city={this.state.selected_city}
          name={this.state.name}
          secretary={this.state.secretary}
          secretary_id={this.state.secretary_id}
          user_id={this.state.user_id}
          {...this.props}
        />

        <Box sx={{
          backgroundColor: '#454545',
        }}
        >

          {this.state.row ? (
            <div style={styles.directionRow}>
              <Box
                sx={{
                  width: '50%',
                  height: window.innerHeight,
                  backgroundColor: '#454545',
                }}
              >

                <Typography style={styles.text}>
                  Árvore de subcategorias da {this.state.secretary}
                </Typography>

                <Container
                  maxWidth="lg"
                  style={{ marginLeft: 15,  }}
                >
                  {this.state.parents ? (
                    <Tree
                      data={this.state.data}
                      style={styles.tree}
                      getChildren={activeNode =>
                        new Promise(async resolve => {
                          await this.handleChildren(activeNode.value);
                          setTimeout(() => {
                            resolve(this.state.children);
                          }, 1000);
                        })
                      }
                    />
                  ) : null}
                </Container>

              </Box>

              <Box
                sx={{
                  backgroundColor: '#323232',
                  margin: 10,
                  marginTop: 30,
                  width: '40%',
                  height: window.innerHeight - 500,
                }}
              >
                {/** cadastro de subcategoria */}
                <Container maxWidth="lg" style={{ marginTop: 15 }}>
                  <form id="add_subcategory" onSubmit={this.handleSubmit}>
                    
                    <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
                      <InputLabel htmlFor="outlined-subcategory_select-native-simple">
                        Subcategoria *
                      </InputLabel>
                      <Select
                        native
                        required
                        value={this.state.subcategory_select}
                        onChange={this.handleChange}
                        label="Subcategoria"
                        inputProps={{
                          name: 'subcategory_select',
                          id: 'outlined-subcategory_select-native-simple',
                        }}
                      >
                        <option value={`select`}>Selecione para criar uma subcategoria desta categoria</option>
                        {this.state.subcategories.map((item) =>
                          <option value={`${item.id}`}>{item.name}</option>
                        )}
                      </Select>
                    </FormControl>

                    <TextField
                      name="subcategory"
                      variant="outlined"
                      required
                      fullWidth
                      id="subcategory"
                      label="Subcategoria"
                      onChange={this.handleChange}
                      value={this.state.subcategory}
                      style={{ marginBottom: 15 }}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={styles.submit}
                      startIcon={<Add />}
                    >
                      Adicionar
                    </Button>

                    {this.state.open ? (
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.setState({ open: false });
                              this.setState({ subcategory: '' });
                              this.setState({ subcategory_select: 'select' });
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {this.state.msg}
                      </Alert>
                    ) : null}

                    {this.state.open_error ? (
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.setState({ open_error: false });
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {this.state.msg}
                      </Alert>
                    ) : null}
                  </form>
                </Container>
              </Box>
            </div>
          ) : (
            <div style={styles.directionColumn}>

                <Box
                  sx={{
                    width: '93%',
                    height: window.innerHeight,
                    backgroundColor: '#454545',
                  }}
                >

                  <Typography style={styles.text}>
                    Árvore de subcategorias da {this.state.secretary}
                  </Typography>

                  <Container maxWidth="lg" style={{ marginLeft: 15 }}>
                    {this.state.parents ? (
                      <Tree
                        data={this.state.data}
                        style={styles.tree}
                        getChildren={activeNode =>
                          new Promise(async resolve => {
                            await this.handleChildren(activeNode.value);
                            setTimeout(() => {
                              resolve(this.state.children);
                            }, 1000);
                          })
                        }
                      />
                    ) : null}
                  </Container>

                </Box>

                <Box
                  sx={{
                    backgroundColor: '#323232',
                    margin: 10,
                    width: '93%',
                    height: 300,
                    marginTop: -400
                  }}
                >
                  {/** cadastro de subcategoria */}
                  <Container maxWidth="lg" style={{ marginTop: 15 }}>
                    <form id="add_subcategory" onSubmit={this.handleSubmit}>

                      <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
                        <InputLabel htmlFor="outlined-subcategory_select-native-simple">
                          Subcategoria *
                        </InputLabel>
                        <Select
                          native
                          required
                          value={this.state.subcategory_select}
                          onChange={this.handleChange}
                          label="Secretaria"
                          inputProps={{
                            name: 'subcategory_select',
                            id: 'outlined-subcategory_select-native-simple',
                          }}
                        >
                          <option value={`select`}>Selecione para criar uma subcategoria desta categoria</option>
                          {this.state.subcategories.map((item) =>
                            <option value={`${item.id}`}>{item.name}</option>
                          )}
                        </Select>
                      </FormControl>

                      <TextField
                        name="subcategory"
                        variant="outlined"
                        required
                        fullWidth
                        id="subcategory"
                        label="Subcategoria"
                        onChange={this.handleChange}
                        value={this.state.subcategory}
                        style={{ marginBottom: 15 }}
                      />

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={styles.submit}
                        startIcon={<Add />}
                      >
                        Adicionar
                      </Button>

                      {this.state.open ? (
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                this.setState({ open: false });
                                this.setState({ subcategory: '' });
                                this.setState({ subcategory_select: 'select' });
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {this.state.msg}
                        </Alert>
                      ) : null}

                      {this.state.open_error ? (
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                this.setState({ open_error: false });
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {this.state.msg}
                        </Alert>
                      ) : null}
                    </form>
                  </Container>
                </Box>

            </div>
          )}

        </Box>

        <FooterHome />

      </div>
    );
  }
}

let styles = {
  html: {
    backgroundColor: '#454545',
    margin: -8
  },

  directionRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },

  directionColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },

  text: {
    margin: 20,
    color: '#fff'
  },

  text2: {
    width: '100%',
    marginBottom: 20,
    color: '#fff'
  },

  accordionDiv: {
    marginTop: 10,
  },

  accordion: {
    width: 350,
    backgroundColor: '#048138',
  },

  list: {
    backgroundColor: '#343434',
    width: 315,
  },

  map: {
    width: '45%',
    height: '70%',
    marginLeft: 10,
    marginTop: 10,
    alignSelf: 'flex-start',
  },

  map2: {
    width: '95%',
    height: '50%',
    marginLeft: 12,
  },

  bt2: {
    margin: 10,
    width: '96.7%',
    textTransform: 'none',
    backgroundColor: '#343434',
    color: '#fff'
  },

  examples: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#454545',
    margin: 10,
    marginTop: 30,
  },

  href: {
    margin: 10,
    color: '#fff'
  },

  avatar: {
    alignSelf: 'center',
    width: 370,
    height: 80,
    margin: 10,
  },

  tree: {
    color: '#fff',
  },

  submit: {
    width: 120,
    margin: 5,
    textTransform: 'none',
    backgroundColor: '#fede5a',
  },

};