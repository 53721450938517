import React from 'react';
import { Switch, Route} from 'react-router-dom';

import Login from './pages/Login';
import Register from './pages/Register';
import MobileReport from './pages/MobileReport';
import HomeAdmin from './pages/HomeAdmin';
import HomeTresRios from './pages/HomeTresRios';
import HomePaulo from './pages/HomePaulo';
import HomeCabo from './pages/HomeCabo';
import CitzenHome from './pages/CitzenHome';
import Details from './pages/Details';
import Subcategories from './pages/Subcategories';
import Process from './pages/Process';
import Search from './pages/Search';

function Routes() {
  return (
    <Switch>
      <Route path='/' exact component={Login} />
      <Route path='/register' exact component={Register} />
      <Route path='/report' exact component={MobileReport} />
      {<Route path='/homeadmin' exact component={HomeAdmin} />}
      {<Route path='/hometresrios' exact component={HomeTresRios} />}
      {<Route path='/homepaulo' exact component={HomePaulo} />}
      {<Route path='/homecabo' exact component={HomeCabo} />}
      <Route path='/citzenhome' exact component={CitzenHome} />
      <Route path='/details' exact component={Details} />
      <Route path='/subcategories' exact component={Subcategories} />
      <Route path='/process' exact component={Process} />
      <Route path='/search' exact component={Search} />
    </Switch>
  );
}

export default Routes;