import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';
import apiCEP from '../services/apiCEP';

import CitzenHeader from '../components/CitzenHeader';
import FooterHome from '../components/FooterHome';

import CitzenTable from '../components/CitzenTable';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Assignment from '@material-ui/icons/Assignment';
import Send from '@material-ui/icons/Send';
import Person from '@material-ui/icons/Person';
import Email from '@material-ui/icons/Email';
import Home from '@material-ui/icons/Home';
import Fingerprint from '@material-ui/icons/Fingerprint';
import Phone from '@material-ui/icons/Phone';
import TextField from '@material-ui/core/TextField';
import AttachFile from '@material-ui/icons/AttachFile';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';

import InputMask from 'react-input-mask';

import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0");
Geocode.setLanguage("pt-BR");

export default class CitzenHome extends Component {

  state = {
    login: '',
    option: '',
    selected_city: this.props.location.state.fields.selected_city,

    user: [],
    user_id: this.props.location.state.fields.user_id,
    documents: [],
    email: '',
    name: '',
    phone: '',
    cep: '',
    cepMask: '99999-999',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    file: null,
    fileName: '',
    document_id: '',
    document_name: '',
    process_number: '',

    secretary: '1',
    secretaries: [],
    subcategory: '1',
    subcategories: [],

    protocol: '',

    msg: '',

    zoom: 19,
    latitude: '',
    longitude: '',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    polygonCoords: [],
    area: '',
    
    formView: false,
    formView2: true,
    formView3: false,

    identification: '',
    house: '',
    location: '',

    fileView: false,
    process_id: '',

    open_error: false,
    open: false,
    open_error2: false,
    open2: false,
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ user_id: this.props.location.state.fields.user_id });
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
    }

    this.handleSubcategories();

    try {
      let resp = await api.get('/user', {
        params: {
          cpf_cnpj: this.state.login,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ user: [resp.data] });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados do usuário. ' + err);
    }

    try {
      let resp = await api.get('/secretaries', {
        params: {
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ secretaries: resp.data });

          } else {
            alert(resp.data);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

    try {
      let resp = await api.get('/checkuserarea', {
        params: {
          cpf_cnpj: this.state.login,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ area: resp.data.area });
            let polygon = resp.data.polygon;
            polygon = polygon.split('"').join('');
            polygon = polygon.split('[').join('');
            polygon = polygon.split('latitude').join('"lat"');
            polygon = polygon.split('longitude').join('"lng"');
            polygon = `[${polygon}]`;
            let polygonJson = JSON.parse(polygon);

            this.setState({ latitude: polygonJson[0].lat });
            this.setState({ longitude: polygonJson[0].lng });

            await this.setState({ polygonCoords: polygonJson });

            //alert(JSON.stringify(this.state.polygonCoords)); 

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados da área. ' + err);
    }

    this.handleDocuments();

  }

  handleSubcategories = async () => {
    try {
      let resp = await api.get('/subcategories2', {
        params: {
          secretary_id: this.state.secretary,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ subcategories: resp.data });
            this.setState({ subcategory: resp.data[0].id });

          } else {
            this.setState({ subcategories: [] });
            this.setState({ subcategory: '' });
          }
        }
      }
    } catch (err) {
      this.setState({ subcategories: [] });
      this.setState({ subcategory: '' });
      this.setState({ msg: 'Não foi possível buscar as subcategorias. ' + err });
      this.setState({ open_error2: true });
      this.setState({ open2: false });
    }
  }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  onMarkerClick = (props, marker) => {

    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  }

  handleNewFile = async e => {
    await e.preventDefault();

    await this.setState({ file: e.target.files[0] });
    const fileSize = this.state.file.size;

    if (fileSize > 5000000) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.setState({ msg: 'Tamanho máximo permitido: 5 MB' });
      this.setState({ open_error: true });
      this.setState({ open: false });
    } else {
      this.setState({ fileName: this.state.file.name });

      const data = new FormData();
      data.append('user_id', this.state.user_id);
      data.append('process_id', this.state.process_id);
      data.append('selected_city', this.state.selected_city);
      data.append('document', this.state.file);

      try {
        let resp = await api.post('/storedocument', data);
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('registred')) {
              //documento salvo
              this.handleDocuments();
            } else {
              this.setState({ msg: 'Não foi possível anexar o documento. ' });
              this.setState({ open: false });
              this.setState({ open_error: true });
            }
          }
        }
      } catch (err) {
        this.setState({ msg: 'Não foi possível anexar o documento. ' + err });
        this.setState({ open: false });
        this.setState({ open_error: true });
      }
    }
  }

  handleDocuments = async () => {

    try {
      let resp = await api.get('/documents', {
        params: {
          user_id: this.state.user_id,
          process_id: this.state.process_id
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            
            this.setState({ documents: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            const finalmsg = msg.toString().split('"').join('');
            await this.setState({ msg: finalmsg });
            await this.setState({ open: false });
            if (this.state.msg === '[]') {
              this.setState({ open_error: false });
            } else {
              this.setState({ open_error: true });
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
      await this.setState({ msg: 'Usuário incorreto. ' + err });
      await this.setState({ open: false });
      this.setState({ open_error: true });
    }

  }

  handleCEP = async () => {

    try{
      let resp = await apiCEP.get(`/ws/${this.state.cep}/json/`);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('cep')) {
            this.setState({ street: resp.data.logradouro });
            this.setState({ district: resp.data.bairro });
            this.setState({ city: resp.data.localidade });
            this.setState({ state: resp.data.uf });
            this.setState({ open_error2: false });
          } else {
            this.setState({ msg: 'CEP não encontrado.' });
            this.setState({ open_error2: true });
            this.setState({ open: false });
          }
        }
      }
    } catch(err) {
      this.setState({ street: '' });
      this.setState({ number: '' });
      this.setState({ district: '' });
      this.setState({ city: '' });
      this.setState({ state: '' });
      this.setState({ msg: 'CEP não encontrado.' });
      this.setState({ open_error2: true });
      this.setState({ open: false });
    }

  }

  handleForm = async () => {

    if ( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.setState({ formView3: true });
    } else {
      this.setState({ formView: true });
      this.setState({ formView2: true });
    }

  }

  handleDeleteDocument = async () => {
  
    try {
      let resp = await api.get('/deletedocument', {
        params: {
          document: this.state.document_name,
          user_id: this.state.user_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('ok')) {
  
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.setState({ msg: 'Documento excluído com sucesso.' });
            this.setState({ open_error: false });
            this.setState({ open: true });
  
            this.componentDidMount();
  
          } else {
            this.setState({ msg: resp.data });
            this.setState({ open_error: true });
            this.setState({ open: false });
          }
        }
      }
    } catch (err) {
      this.setState({ message: 'Não foi possível excluir este documento. ' + err });
      this.setState({ open_error: true });
      this.setState({ open: false });
    }
  }

  handleSubmit = async (e) => {

    e.preventDefault();

    let city = 'Três Rios - RJ'
    if (this.state.selected_city === 'paulo') {
      city = 'Engenheiro Paulo de Frontin - RJ';
    } else {
      if (this.state.selected_city === 'cabo') {
        city = 'Cabo Frio - RJ';
      } else {
        city = 'Três Rios - RJ';
      }
    }

    let address = `${this.state.street}, ${this.state.number} - ${this.state.district}, ${city}`;

    await Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({
          latitude: lat,
          longitude: lng,
        });
      },
      (error) => {
        this.setState({ msg: 'Não foi possível gerar latitude e longitude do endereço informado. ' + error });
        this.setState({ open_error2: true });
        this.setState({ open2: false });
      }
    );

    const data = new FormData();

    data.append('protocol', this.state.protocol);
    data.append('process_number', this.state.process_number);
    data.append('user_id', this.state.user_id);
    data.append('secretary_id', this.state.secretary);
    data.append('subcategory', this.state.subcategory);
    data.append('cep', this.state.cep);
    data.append('street', this.state.street);
    data.append('number', this.state.number);
    data.append('complement', this.state.complement);
    data.append('district', this.state.district);
    data.append('status', '1');
    data.append('latitude', this.state.latitude);
    data.append('longitude', this.state.longitude);

    data.append('selected_city', this.state.selected_city);

    try {
      let resp = await api.post('/process', data);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('registred')) {

            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.setState({ msg: 'Cadastro realizado com sucesso. É possível anexar arquivos ao processo.' });
            this.setState({ open_error2: false });
            this.setState({ open2: true });

            this.setState({ process_id: resp.data.registred });
            this.setState({ fileView: true });

          } else {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.setState({ msg: resp.data });
            this.setState({ open_error2: true });
            this.setState({ open2: false });
            this.setState({ fileView: false });
          }
        }
      }
    } catch (err) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.setState({ msg: 'Não foi possível incluir este processo. ' + err });
      this.setState({ open_error2: true });
      this.setState({ open2: false });
      this.setState({ fileView: false });
    }

  }

  handleChangeSecretary = async e => {
    await this.setState({ [e.target.name]: e.target.value });

    this.handleSubcategories();
  }

  handleChange = async e => {
    await this.setState({ [e.target.name]: e.target.value });
  }

  render() {

    return(

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        margin: -10,
      }}
      >

        <CitzenHeader history={this.props.history} {...this.props}/>

        <Box sx={{
          backgroundColor: '#454545',
        }}
        >

          <div style={styles.directionRow}>

            <Container maxWidth="xs" style={{ margin: 15, backgroundColor: '#343434' }}>

              {this.state.user.map((item) =>
                <div style={{ padding: 10 }}>
                  <Typography variant="h6" color="textSecondary" align="left">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={styles.icon}>
                        <Person />
                      </div>
                      {item.name}
                    </div>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" align="left">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={styles.icon}>
                        <Email />
                      </div>
                      {item.email}
                    </div>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" align="left">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={styles.icon}>
                        <Home />
                      </div>
                      {`${item.street}, ${item.number} - ${item.district}, ${item.city} - ${item.state}`}
                    </div>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" align="left">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={styles.icon}>
                        <Fingerprint />
                      </div>
                      {item.cpf_cnpj}
                    </div>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" align="left">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={styles.icon}>
                        <Phone />
                      </div>
                      {item.phone}
                    </div>
                  </Typography>
                </div>
              )}

              <Button
                variant="contained"
                color="primary"
                style={styles.bt}
                startIcon={<Assignment />}
                onClick={() => {
                  this.handleForm();
                }}
              >
                Requerimento
              </Button>

              {/* começa aqui */}
              {this.state.formView3 ? (

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <form id="new_process" onSubmit={this.handleSubmit}>

                    {this.state.open2 ? (
                      <Alert
                        severity="success"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.setState({ msg: '' });
                              this.setState({ open2: false });
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {this.state.msg}
                      </Alert>
                    ) : null}

                    {this.state.open_error2 ? (
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.setState({ open_error2: false });
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {this.state.msg}
                      </Alert>
                    ) : null}

                    <Typography variant="h6" color="textSecondary" align="left">
                      <div style={{ margin: 10 }}>
                        Categorização
                      </div>
                    </Typography>

                    <FormControl variant="outlined" style={{ marginBottom: 15, width: '90%' }}>
                      <InputLabel htmlFor="outlined-secretary-native-simple">
                        Secretaria *
                      </InputLabel>
                      <Select
                        native
                        required
                        value={this.state.secretary}
                        onChange={this.handleChangeSecretary}
                        label="Secretaria"
                        inputProps={{
                          name: 'secretary',
                          id: 'outlined-secretary-native-simple',
                        }}
                      >
                        {this.state.secretaries.map((item) =>
                          <option value={`${item.id}`}>{item.name}</option>
                        )}
                      </Select>
                    </FormControl>

                    <FormControl variant="outlined" style={{ marginBottom: 15, width: '90%' }}>
                      <InputLabel htmlFor="outlined-subcategory-native-simple">
                        Subcategoria (assunto) *
                      </InputLabel>
                      <Select
                        native
                        required
                        value={this.state.subcategory}
                        onChange={this.handleChange}
                        label="Subcategoria (assunto)"
                        inputProps={{
                          name: 'subcategory',
                          id: 'outlined-subcategory-native-simple',
                        }}
                      >
                        {this.state.subcategories.map((item) =>
                          <option value={`${item.id}`}>{item.name}</option>
                        )}
                      </Select>
                    </FormControl>

                    <Typography variant="h6" color="textSecondary" align="left">
                      <div style={{ margin: 10 }}>
                        Localização
                      </div>
                    </Typography>

                    <InputMask
                      {...this.props.value}
                      mask={this.state.cepMask}
                      maskChar=" "
                      name="cep"
                      id="cep"
                      type="text"
                      onChange={this.handleChange}
                      onBlur={this.handleCEP}
                      value={this.state.cep}
                    >
                      {(inputProps) =>
                        <TextField
                          {...inputProps}
                          variant="outlined"
                          margin="normal"
                          label="CEP"
                          style={{ marginBottom: 15, marginTop: -2, width: '90%' }}
                        />
                      }
                    </InputMask>

                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                      <TextField
                        name="street"
                        variant="outlined"
                        required
                        id="street"
                        label="Rua"
                        InputProps={{ style: styles.input }}
                        style={{ width: '60%'}}
                        onChange={this.handleChange}
                        value={this.state.street}
                      />
                      <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>
                        -
                      </Typography>
                      <TextField
                        name="number"
                        variant="outlined"
                        required
                        id="number"
                        label="Número"
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.number}
                        style={{ width: 93 }}
                      />
                    </div>

                    <TextField
                      name="complement"
                      variant="outlined"
                      id="complement"
                      label="Complemento"
                      InputProps={{ style: styles.input }}
                      onChange={this.handleChange}
                      value={this.state.complement}
                      style={{ marginBottom: 15, width: '90%' }}
                    />

                    <TextField
                      name="district"
                      variant="outlined"
                      required
                      id="district"
                      label="Bairro"
                      InputProps={{ style: styles.input }}
                      onChange={this.handleChange}
                      value={this.state.district}
                      style={{ marginBottom: 15, width: '90%' }}
                    />

                    <Typography variant="h6" color="textSecondary" align="left">
                      <div style={{ margin: 10 }}>
                        Protocolo
                      </div>
                    </Typography>

                    <TextField
                      name="protocol"
                      variant="outlined"
                      required
                      id="protocol"
                      label="Nº de protocolo"
                      InputProps={{ style: styles.input }}
                      onChange={this.handleChange}
                      value={this.state.protocol}
                      style={{ marginBottom: 15, width: '90%' }}
                    />

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={styles.submit}
                        startIcon={<Close />}
                        onClick={() => {
                          this.setState({ formView3: false });
                          this.componentDidMount();
                        }}
                      >
                        Cancelar
                      </Button>

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={styles.submit}
                        startIcon={<Send />}
                      >
                        Homologar
                      </Button>

                    </div>
                  </form>

                  {this.state.fileView ? (
                    <Container maxWidth="sm" style={{ marginTop: 10 }}>
                      <Typography variant="h6" color="textSecondary" align="left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={styles.icon}>
                            <AttachFile />
                          </div>
                          Anexo de arquivos
                        </div>
                      </Typography>

                      <Container maxWidth="lg" style={{ marginTop: 10, marginBottom: 10, backgroundColor: '#454545' }}>

                        {this.state.open ? (
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  this.setState({ msg: '' });
                                  this.setState({ open: false });
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {this.state.msg}
                          </Alert>
                        ) : null}

                        {this.state.open_error ? (
                          <Alert
                            severity="error"
                            style={{ marginBottom: 10 }}
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  this.setState({ open_error: false });
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {this.state.msg}
                          </Alert>
                        ) : null}

                        <div style={{ display: 'flex', flexDirection: 'column', margin: 10, marginTop: 20 }}>
                          {this.state.documents.map((item) =>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <a target="_blank" href={`${apiStringServer}/documents/${item.file}`}>
                                <Typography variant="subtitle1" color="textSecondary" align="left">
                                  {item.file}
                                </Typography>
                              </a>

                              <IconButton
                                aria-label="close"
                                color="#800000"
                                style={{ marginTop: -15 }}
                                onClick={async () => {
                                  await this.setState({ fileName: '' });
                                  await this.setState({ document_id: item.id });
                                  await this.setState({ document_name: item.file });
                                  this.handleDeleteDocument();
                                }}
                              >
                                <Close />
                              </IconButton>
                            </div>
                          )}
                        </div>

                        <input
                          accept="file_extension|audio/*|video/*|image/*|media_type"
                          style={{ display: 'none' }}
                          id="button-attach"
                          multiple
                          type="file"
                          onChange={this.handleNewFile}
                        />
                        <label htmlFor="button-attach">
                          <Button
                            component="span"
                            variant="contained"
                            size="small"
                            color="primary"
                            style={styles.bt}
                            startIcon={<AttachFile />}
                          >
                            Anexar documento
                          </Button>
                        </label>

                      </Container>
                    </Container>
                  ) : null}
                </Box>

              ) : null}
              {/* termina aqui */}

            </Container>

            {this.state.formView ? (
              <Container maxWidth="md" style={{ margin: 15, backgroundColor: '#343434' }}>

                {this.state.formView2 ? (
                  <Container maxWidth="sm" style={{ margin: 15, display: 'flex', flexDirection: 'row' }}>
                    <form id="new_process" onSubmit={this.handleSubmit}>

                      {this.state.open2 ? (
                        <Alert
                          severity="success"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                this.setState({ msg: '' });
                                this.setState({ open2: false });
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {this.state.msg}
                        </Alert>
                      ) : null}

                      {this.state.open_error2 ? (
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                this.setState({ open_error2: false });
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {this.state.msg}
                        </Alert>
                      ) : null}

                      <Typography variant="h6" color="textSecondary" align="left">
                        <div style={{ margin: 10 }}>
                          Categorização
                        </div>
                      </Typography>

                      <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
                        <InputLabel htmlFor="outlined-secretary-native-simple">
                          Secretaria *
                        </InputLabel>
                        <Select
                          native
                          required
                          value={this.state.secretary}
                          onChange={this.handleChangeSecretary}
                          label="Secretaria"
                          inputProps={{
                            name: 'secretary',
                            id: 'outlined-secretary-native-simple',
                          }}
                        >
                          {this.state.secretaries.map((item) =>
                            <option value={`${item.id}`}>{item.name}</option>
                          )}
                        </Select>
                      </FormControl>

                      <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
                        <InputLabel htmlFor="outlined-subcategory-native-simple">
                          Subcategoria (assunto) *
                        </InputLabel>
                        <Select
                          native
                          required
                          value={this.state.subcategory}
                          onChange={this.handleChange}
                          label="Subcategoria (assunto)"
                          inputProps={{
                            name: 'subcategory',
                            id: 'outlined-subcategory-native-simple',
                          }}
                        >
                          {this.state.subcategories.map((item) =>
                            <option value={`${item.id}`}>{item.name}</option>
                          )}
                        </Select>
                      </FormControl>

                      <Typography variant="h6" color="textSecondary" align="left">
                        <div style={{ margin: 10 }}>
                          Localização
                        </div>
                      </Typography>

                      <InputMask
                        {...this.props.value}
                        mask={this.state.cepMask}
                        fullWidth
                        maskChar=" "
                        name="cep"
                        id="cep"
                        type="text"
                        onChange={this.handleChange}
                        onBlur={this.handleCEP}
                        value={this.state.cep}
                      >
                        {(inputProps) =>
                          <TextField
                            {...inputProps}
                            variant="outlined"
                            margin="normal"
                            label="CEP"
                            style={{ marginBottom: 15, marginTop: -2 }}
                          />
                        }
                      </InputMask>

                      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                        <TextField
                          name="street"
                          variant="outlined"
                          required
                          fullWidth
                          id="street"
                          label="Rua"
                          InputProps={{ style: styles.input }}
                          onChange={this.handleChange}
                          value={this.state.street}
                        />
                        <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>
                          -
                        </Typography>
                        <TextField
                          name="number"
                          variant="outlined"
                          required
                          id="number"
                          label="Número"
                          InputProps={{ style: styles.input }}
                          onChange={this.handleChange}
                          value={this.state.number}
                          style={{ width: 150 }}
                        />
                      </div>

                      <TextField
                        name="complement"
                        variant="outlined"
                        fullWidth
                        id="complement"
                        label="Complemento"
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.complement}
                        style={{ marginBottom: 15 }}
                      />

                      <TextField
                        name="district"
                        variant="outlined"
                        required
                        fullWidth
                        id="district"
                        label="Bairro"
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.district}
                        style={{ marginBottom: 15 }}
                      />

                      <Typography variant="h6" color="textSecondary" align="left">
                        <div style={{ margin: 10 }}>
                          Protocolo
                        </div>
                      </Typography>

                      <TextField
                        name="protocol"
                        variant="outlined"
                        required
                        fullWidth
                        id="protocol"
                        label="Nº de protocolo"
                        InputProps={{ style: styles.input }}
                        onChange={this.handleChange}
                        value={this.state.protocol}
                        style={{ marginBottom: 15 }}
                      />

                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={styles.submit}
                          startIcon={<Close />}
                          onClick={() => {
                            this.setState({ formView: false });
                            this.componentDidMount();
                          }}
                        >
                          Cancelar
                        </Button>

                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={styles.submit}
                          startIcon={<Send />}
                        >
                          Homologar
                        </Button>

                      </div>
                    </form>

                    {this.state.fileView ? (
                      <Container maxWidth="sm" style={{ marginTop: 10 }}>
                        <Typography variant="h6" color="textSecondary" align="left">
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={styles.icon}>
                              <AttachFile />
                            </div>
                            Anexo de arquivos
                          </div>
                        </Typography>

                        <div style={styles.container2}>

                          {this.state.open ? (
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    this.setState({ msg: '' });
                                    this.setState({ open: false });
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {this.state.msg}
                            </Alert>
                          ) : null}

                          {this.state.open_error ? (
                            <Alert
                              severity="error"
                              style={{ marginBottom: 10 }}
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    this.setState({ open_error: false });
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {this.state.msg}
                            </Alert>
                          ) : null}

                          <div style={{ display: 'flex', flexDirection: 'column', margin: 10, marginTop: 20 }}>
                            {this.state.documents.map((item) =>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <a target="_blank" href={`${apiStringServer}/documents/${item.file}`}>
                                  <Typography variant="subtitle1" color="textSecondary" align="left">
                                    {item.file}
                                  </Typography>
                                </a>

                                <IconButton
                                  aria-label="close"
                                  color="#800000"
                                  style={{ marginTop: -15 }}
                                  onClick={async () => {
                                    await this.setState({ fileName: '' });
                                    await this.setState({ document_id: item.document_id });
                                    await this.setState({ document_name: item.file });
                                    this.handleDeleteDocument();
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </div>
                            )}
                          </div>

                          <input
                            accept="file_extension|audio/*|video/*|image/*|media_type"
                            style={{ display: 'none' }}
                            id="button-attach"
                            multiple
                            type="file"
                            onChange={this.handleNewFile}
                          />
                          <label htmlFor="button-attach">
                            <Button
                              component="span"
                              variant="contained"
                              size="small"
                              color="primary"
                              style={styles.bt}
                              startIcon={<AttachFile />}
                            >
                              Anexar documento
                            </Button>
                          </label>

                        </div>
                      </Container>
                    ) : null}

                  </Container>
                ) : (
                  <div style={styles.examples}>

                  </div>
                )}
              </Container>
              
            ) : null }

          </div>

          <Container maxWidth="xl" style={{ marginBottom: 30 }}>
            <CitzenTable
              history={this.props.history}
              vars={this.state}
              selected_city={this.state.selected_city}
              {...this.props}
            />
          </Container>

        </Box>

        <FooterHome />

      </div>

    );

  }

}

let styles = {

  html: {
    backgroundColor: '#454545',
    height: window.innerHeight + 250,
    width: window.innerWidth + 2,
    margin: -10,
    paddingBottom: 200,
  },

  container: {
    height: 600,
    width: window.innerWidth - (window.innerWidth / 1.8),
    backgroundColor: '#343434',
    margin: 10
  },

  container2: {
    height: 520,
    width: 400,
    backgroundColor: '#454545',
    marginTop: 10,
  },

  directionRow: { 
    display: 'flex', 
    flexDirection: 'row' 
  },

  icon: {
    marginTop: 2.5,
    marginRight: 5,
  },

  map: {
    height: 600,
    width: window.innerWidth - (window.innerWidth / 2),
    marginLeft: 10,
    marginTop: 10,
    alignSelf: 'flex-start',
  },

  bt: {
    margin: 10,
    marginBottom: 10,
    textTransform: 'none',
    backgroundColor: '#fede5a',
  },

  bt2: {
    margin: 10,
    width: '96.7%',
    textTransform: 'none',
    backgroundColor: '#fede5a',
  },

  examples: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#454545',
    margin: 10,
    marginTop: 30,
  },

  href: {
    margin: 10,
    color: '#fff'
  },

  text: {
    width: 335,
  },

  submit: {
    width: 140,
    margin: 5,
    textTransform: 'none',
    backgroundColor: '#fede5a',
  },

}