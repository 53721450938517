import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import Visibility from '@material-ui/icons/Visibility';
import Share from '@material-ui/icons/Share';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

let columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'protocol',
    headerName: 'Protocolo',
    width: 150,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Protocolo '}
        <span role="img" aria-label="enjoy">
          📄
        </span>
      </strong>
    ),
  },
  {
    field: 'process',
    headerName: 'Nº do Processo',
    width: 170,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Nº do Processo '}
        <span role="img" aria-label="enjoy">
          ♎
        </span>
      </strong>
    ),
  },
  {
    field: 'secretary',
    headerName: 'Secretaria',
    width: 250,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Secretaria '}
        <span role="img" aria-label="enjoy">
          🏛️
        </span>
      </strong>
    ),
  },
  {
    field: 'subcategory',
    headerName: 'Subcategoria',
    width: 330,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Subcategoria '}
        <span role="img" aria-label="enjoy">
          🏷️
        </span>
      </strong>
    ),
  },
  {
    field: 'city',
    headerName: 'Cidade',
    width: 200,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Cidade '}
        <span role="img" aria-label="enjoy">
          🏙️
        </span>
      </strong>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    editable: false,
    renderHeader: () => (
      <strong>
        {'Status '}
        <span role="img" aria-label="enjoy">
          ✔️
        </span>
      </strong>
    ),
  },
  {
    field: 'actions',
    headerName: 'Ações',
    renderCell: () => (
      <Actions />
    ),
    sortable: false,
    width: 100,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    disableColumnMenu: true,
    disableReorder: true,
  },
];

let rows = [];

let rowsArray = [];

let process = '';
let user = '';

let selected_city = '';

export class Actions extends Component {

  state = {
    openDialog: false,
    status: '1',

    page_rows: [],
  }

  handleSee = async () => {

    setTimeout(() => {
      window.open(`${apiString}/process?process_id=${process}&user=${user}&selected_city=${selected_city}`, '_blank');
    }, 1500);

  }

  handleEdit = async () => {
    //await this.setState({ openDialog: true });
    //let 
    //alert(JSON.stringify(rowsArray[0]));
  }

  handleDelete = async () => {
    
  }

  onStatusChange = async () => {
    if (selected_city.toLowerCase().includes('três rios')) {
      selected_city = 'rios';
    } else {
      if (selected_city.toLowerCase().includes('mendes')) {
        selected_city = 'mendes';
      } else {
        if (selected_city.toLowerCase().includes('paulo de frontin')) {
          selected_city = 'paulo';
        } else {
          if (selected_city.toLowerCase().includes('cabo')) {
            selected_city = 'cabo';
          } else {
            selected_city = 'rio de janeiro';
          }
        }
      }
    }

    try {
      let resp = await api.get('/updateuser', {
        params: {
          process,
          status: this.state.status,
          selected_city: selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('edited')) {

            window.location.reload();

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os dados do usuário. ' + err);
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return(
      <div>
        <IconButton
          color="inherit"
          size="small"
          aria-label="see"
          onClick={this.handleSee}
        >
          <Visibility fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="share"
          onClick={this.handleShare}
        >
          <Share fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="edit"
          onClick={this.handleEdit}
        >
          <Edit fontSize="small" />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          aria-label="delete"
          onClick={this.handleDelete}
        >
          <Delete fontSize="small" />
        </IconButton>

        <Dialog open={this.state.openDialog} onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Alteração</DialogTitle>
          <DialogContent>
            <DialogContentText color="#fff">
              Selecione ou preencha os campos que deseja alterar.
            </DialogContentText>

            <FormControl variant="outlined" fullWidth style={{ marginBottom: 20 }}>
              <InputLabel htmlFor="outlined-theme-native-simple">
                Status
              </InputLabel>
              <Select
                autoFocus
                native
                value={this.state.status}
                onChange={this.handleChange}
                label="Status"
                inputProps={{
                  name: 'status',
                  id: 'outlined-status-native-simple',
                }}
              >
                <option value="1">Pendente</option>
                <option value="2">Regularizado</option>
                <option value="3">Negado</option>
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog: false })} style={{ color: '#fede5a' }}>
              Cancelar
            </Button>
            <Button onClick={async () => {
              this.setState({ openDialog: false });
              this.onStatusChange();
            }} style={{ color: '#fede5a' }}>
              Alterar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

let users = [];

export default class CitzenTable extends Component {

  state = {
    user_id: this.props.vars.user_id,
    selected_city: this.props.selected_city,
    page_rows: []
  }

  async componentDidMount() {

    await this.setState({ user_id: this.props.vars.user_id});
    await this.setState({ selected_city: this.props.selected_city});

    user = this.state.user_id;
    selected_city = this.state.selected_city;
    try {
      let resp = await api.get('/userprocess', {
        params: {
          user_id: this.state.user_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          //alert(JSON.stringify(resp.data));
          if (JSON.stringify(resp.data).includes('protocol')) {
            users = resp.data;

            for (let i = 0; i < resp.data.length; i++) {
              let status = '';
              if (resp.data[i].status === 1) {
                status = '🟡 Pendente';
              } else {
                if (resp.data[i].status === 2) {
                  status = '🟢 Regularizado';
                } else {
                  if (resp.data[i].status === 3) {
                    status = '🔴 Negado';
                  }
                }
              }
              let aux = {
                id: `${resp.data[i].id}`, 
                protocol: `${resp.data[i].protocol}`, 
                process: `${resp.data[i].process_number}`, 
                secretary: `${resp.data[i].secretary_name}`, 
                subcategory: `${resp.data[i].subcategory_name}`, 
                city: `${resp.data[i].city} - ${resp.data[i].state}`,
                status: status
              };
              rows = Object.assign([], rows);
              rows.push(aux);
            }

            this.setState({ page_rows: rows });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg)
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

    this.setState({ page_rows: rows });

  }

  render() {

    return(

      <DataGrid
        style={{ height: 500 }}
        rows={rows}
        columns={columns}
        pageSize={7}
        rowsPerPageOptions={[7]}
        disableSelectionOnClick
        localeText={ptBR.props.MuiDataGrid.localeText}
        onCellClick={async (row) => {
          rowsArray = [];
          await rowsArray.push(row);
          process = '';
          process = rowsArray[0].row.id;
        }}
      />

    );

  }

}