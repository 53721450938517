import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import Header from '../components/Header';
import FooterHome from '../components/FooterHome';
import Typography from '@material-ui/core/Typography';
import Person from '@material-ui/icons/Person';
import Email from '@material-ui/icons/Email';
import Home from '@material-ui/icons/Home';
import Fingerprint from '@material-ui/icons/Fingerprint';
import Phone from '@material-ui/icons/Phone';
import LocationOn from '@material-ui/icons/LocationOn';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';

import { Map, GoogleApiWrapper, Marker, Polygon } from 'google-maps-react';

export class Details extends Component {
  
  state = {
    cpf_cnpj: '',
    login: '',
    user_id: '',
    option: '',
    selected_city: '',
    documents: [],

    zoom: 19,
    latitude: '',
    longitude: '',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    user: [],
    polygonCoords: [],
    area: '',

    expanded: false,
    openDialog: false,

    msg: '',
    open: false,
    open_error: false,
  }

  async componentDidMount() {

    if (this.props.location.state) {

      await this.setState({ cpf_cnpj: this.props.location.state.fields.cpf_cnpj });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });

    } else {

      const idx = document.URL.indexOf('?');

      var params = new Array();
      if (idx != -1) {
        var pairs = document.URL.substring(idx + 1, document.URL.length).split('&');
        //alert(pairs);
        for (var i = 0; i < pairs.length; i++) {
          let nameVal = pairs[i].split(',');
          nameVal = pairs[i].replace('cpf_cnpj=', '');
          nameVal = nameVal.replace('lat=', '');
          nameVal = nameVal.replace('lng=', '');
          nameVal = nameVal.replace('selected_city=', '');
          params.push(nameVal);
        }
      }

      await this.setState({ cpf_cnpj: params[0] });
      await this.setState({ latitude: params[1] });
      await this.setState({ longitude: params[2] });
      await this.setState({ selected_city: params[3] });

    }

    try {
      let resp = await api.get('/user', {
        params: {
          cpf_cnpj: this.state.cpf_cnpj,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ user: [resp.data] });

            await this.setState({ user_id: JSON.stringify(resp.data.id) });

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados do usuário. ' + err);
    }

    try {
      let resp = await api.get('/checkuserarea', {
        params: {
          cpf_cnpj: this.state.cpf_cnpj,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            this.setState({ area: resp.data.area });
            let polygon = resp.data.polygon;
            polygon = polygon.split('"').join('');
            polygon = polygon.split('[').join('');
            polygon = polygon.split('latitude').join('"lat"');
            polygon = polygon.split('longitude').join('"lng"');
            polygon = `[${polygon}]`;
            let polygonJson = JSON.parse(polygon);

            this.setState({ latitude: polygonJson[0].lat });
            this.setState({ longitude: polygonJson[0].lng });

            await this.setState({ polygonCoords: polygonJson });

            //alert(JSON.stringify(this.state.polygonCoords)); 

          } else {
            let msg = JSON.stringify(resp.data);
            console.log(msg);
          }
        }
      }
    } catch (err) {
      console.log('Não foi possível buscar os dados da área. ' + err);
    }

    this.handleDocuments();

  }

  handleDocuments = async () => {

    try {
      let resp = await api.get('/documents', {
        params: {
          user_id: this.state.user_id
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            
            this.setState({ documents: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            const finalmsg = msg.toString().split('"').join('');
            await this.setState({ msg: finalmsg });
            await this.setState({ open: false });
            if (this.state.msg == '[]') {
              this.setState({ open_error: false });
            } else {
              this.setState({ open_error: true });
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
      await this.setState({ msg: 'Usuário incorreto. ' + err });
      await this.setState({ open: false });
      this.setState({ open_error: true });
    }

  }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  onMarkerClick = (props, marker) => {

    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.html}>
          <Header history={this.props.history} {...this.props}/>

          <div style={styles.directionRow}>

            <div style={styles.container}>

              {this.state.user.map((item) =>
                <div style={{ padding: 10 }}>
                  <Typography variant="h6" color="textSecondary" align="left">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={styles.icon}>
                        <Person />
                      </div>
                      {item.name}
                    </div>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" align="left">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={styles.icon}>
                        <Email />
                      </div>
                      {item.email}
                    </div>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" align="left">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={styles.icon}>
                        <Home />
                      </div>
                      {`${item.street}, ${item.number} - ${item.district}, ${item.city} - ${item.state}`}
                    </div>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" align="left">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={styles.icon}>
                        <Fingerprint />
                      </div>
                      {item.cpf_cnpj}
                    </div>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" align="left">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={styles.icon}>
                        <Phone />
                      </div>
                      {item.phone}
                    </div>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" align="left">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={styles.icon}>
                        <LocationOn />
                      </div>
                      {`Área: ${this.state.area} m²`}
                    </div>
                  </Typography>
                </div>
              )}

              <div style={styles.container2}>

                {this.state.open ? (
                  <Alert
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.handleClose();
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {this.state.msg}
                  </Alert>
                ) : null}

                {this.state.open_error ? (
                  <Alert
                    severity="error"
                    style={{ marginBottom: 10 }}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.setState({ open_error: false });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {this.state.msg}
                  </Alert>
                ) : null}

                <Typography variant="h6" color="textSecondary" align="center">
                  Documentos
                </Typography>

                <div style={{ display: 'flex', flexDirection: 'column', margin: 10, marginTop: 20 }}>
                  {this.state.documents.map((item) =>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <a target="_blank" href={`${apiStringServer}/documents/${item.file}`}>
                        <Typography variant="subtitle1" color="textSecondary" align="left">
                          {item.file}
                        </Typography>
                      </a>

                      <a target="_blank" href={`${apiStringServer}/documents/${item.file}`}>
                        <IconButton
                          aria-label="close"
                          color="#800000"
                          style={{ marginTop: -15 }}
                          onClick={async () => {
                            await this.setState({ fileName: '' });
                            //await this.setState({ document_id: item.document_id });
                            //await this.setState({ document_name: item.file });
                            //this.handleDeleteDocument();
                          }}
                        >
                          <Visibility />
                        </IconButton>
                      </a>
                    </div>
                  )}
                </div>

              </div>

            </div>

            <div>
              <Map
                key={'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0'}
                google={this.props.google}
                zoom={this.state.zoom}
                style={styles.map}
                mapType="hybrid"
                center={{
                  lat: this.state.latitude,
                  lng: this.state.longitude
                }}
                onClick={this.onMapClicked}
              >

                <Polygon
                  paths={this.state.polygonCoords}
                  strokeColor="#fede5a"
                  strokeOpacity={0.5}
                  strokeWeight={2}
                  fillColor="#fede5a"
                  fillOpacity={0.7} />

                <Marker
                  position={{
                    lat: this.state.latitude,
                    lng: this.state.longitude
                  }}
                  onClick={async (props, marker) => {
                    this.onMarkerClick(props, marker);
                  }}
                  name={`Local`}
                />

              </Map>
            </div>
          </div>

        </div>
        <FooterHome />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDC6df7s7nceeG2sHhFGu0-nzLppDiHpe0',
  language: 'pt-BR'
})(Details)

let styles = {
  html: {
    backgroundColor: '#454545',
    height: window.innerHeight + 150,
    width: window.innerWidth,
    margin: -10,
    paddingBottom: 50,
  },

  container: {
    height: 600,
    width: window.innerWidth - (window.innerWidth / 1.8),
    backgroundColor: '#343434',
    marginLeft: 10,
    marginTop: 10,
  },

  container2: {
    height: 340,
    width: 600,
    backgroundColor: '#454545',
    margin: 10,
  },

  directionRow: { 
    display: 'flex', 
    flexDirection: 'row' 
  },

  icon: {
    marginTop: 2.5,
    marginRight: 5,
  },

  directionColumn: { 
    display: 'flex', 
    flexDirection: 'column' 
  },

  map: {
    height: 600,
    width: window.innerWidth - (window.innerWidth / 2),
    marginLeft: 10,
    marginTop: 10,
    alignSelf: 'flex-start',
  },
};