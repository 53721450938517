import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import Routes from './routes';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#fede5a',
    },
    secondary :{
      main: '#E5E6F0',
    }
  },
});


function App() {
  return (
    <BrowserRouter>
      <html style={styles.html}>
        <head>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
        </head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </html>
    </BrowserRouter>
  );
}

let styles = {
  html: {
    backgroundColor: '#343434',
  }
};

export default App;
