import React, { Component } from 'react';
import apiMunicipios from '../services/apiMunicipios';

import Button from '@material-ui/core/Button';
import NavigateNext from '@material-ui/icons/NavigateNext';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import Header from '../components/Header';
import FooterHome from '../components/FooterHome';

let anotherItems = ['Administrativo', 'Defesa da cidadania', 'Direitos humanos', 'Infância e Juventude', 'Patrimônio cultural',
'Serviços públicos', 'Notícias'];

export default class Search extends Component {

  state = {
    user_id: '',
    login: '',
    option: '',
    selected_city: 'rios',
    secretary: '',
    secretary_id: '',

    subcategory: '',
    subcategory_select: 'select',
    subcategories: [],
    parents: [],

    zoom: 12,
    mobilezoom: 11,
    latitude: '',
    longitude: '',
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    places: [],

    cpf_cnpj: '',
    name: '',
    email: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',

    new_lat: '',
    new_lng: '',

    expanded: false,
    openDialog: false,

    renderTable: false,

    row: true,

    data: [],
    children: [],

    open: false,
    open_error: false,
    msg: '',

    iframeUrl: '',
  }
  
  async componentDidMount() {
    if (this.props.location.state) {
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
      await this.setState({ name: this.props.location.state.fields.name.split('"').join('') });
      await this.setState({ secretary: this.props.location.state.fields.secretary.split('"').join('') });
      await this.setState({ secretary_id: this.props.location.state.fields.secretary_id });
      await this.setState({ user_id: this.props.location.state.fields.user_id });
    } else {
      const idx = document.URL.indexOf('?');

      var params = [];
      if (idx !== -1) {
        var pairs = document.URL.substring(idx + 1, document.URL.length).split('&');
        //alert(pairs);
        for (var i = 0; i < pairs.length; i++) {
          let nameVal = pairs[i].split(',');
          nameVal = pairs[i].replace('login=', '');
          nameVal = nameVal.replace('option=', '');
          nameVal = nameVal.replace('selected_city=', '');
          nameVal = nameVal.replace('name=', '');
          nameVal = nameVal.replace('secretary=', '');
          nameVal = nameVal.replace('secretary_id=', '');
          nameVal = nameVal.replace('user_id=', '');
          params.push(nameVal);
        }
      }

      await this.setState({ login: decodeURI(params[0]) });
      await this.setState({ option: decodeURI(params[1]) });
      await this.setState({ selected_city: decodeURI(params[2]) });
      await this.setState({ name: decodeURI(params[3]) });
      await this.setState({ secretary: decodeURI(params[4]) });
      await this.setState({ secretary_id: decodeURI(params[5]) });
      await this.setState({ user_id: decodeURI(params[6]) });
    }

    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.setState({ row: false });
    } else {
      this.setState({ row: true });
    }
  }

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  }

  render() {

    return(

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        margin: -10,
      }}
      >

        <Header
          history={this.props.history}
          login={this.state.login}
          option={this.state.option}
          selected_city={this.state.selected_city}
          name={this.state.name}
          secretary={this.state.secretary}
          secretary_id={this.state.secretary_id}
          user_id={this.state.user_id}
          {...this.props}
        />

        <Box sx={{
          backgroundColor: '#454545',
        }}
        >

          {this.state.row ? (
            <div style={styles.directionRow}>
              <Box
                sx={{
                  width: '30%',
                  height: window.innerHeight,
                  backgroundColor: '#454545',
                }}
              >
                {anotherItems.map((item) =>
                  <div>
                    {item === 'Notícias' ? (
                      <div>
                        {this.state.selected_city === 'rios' ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://news.google.com/search?q=tr%C3%AAs%20rios&hl=pt-BR&gl=BR&ceid=BR%3Apt-419'}
                          >
                            <Accordion
                              expanded={this.state.expanded === `panel${item}`}
                              onChange={this.handleChangePanel(`panel${item}`)}
                              style={styles.button}
                            //onClick={() => readFile()}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${item}a-content`}
                                id={`panel${item}a-header`}
                              >
                                <Typography>
                                  {item}
                                </Typography>
                              </AccordionSummary>
                            </Accordion>
                          </a>
                        ) ? this.state.selected_city === 'paulo' (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://news.google.com/search?q=paulo%20de%20frontin&hl=pt-BR&gl=BR&ceid=BR%3Apt-419'}
                          >
                            <Accordion
                              expanded={this.state.expanded === `panel${item}`}
                              onChange={this.handleChangePanel(`panel${item}`)}
                              style={styles.button}
                            //onClick={() => readFile()}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${item}a-content`}
                                id={`panel${item}a-header`}
                              >
                                <Typography>
                                  {item}
                                </Typography>
                              </AccordionSummary>
                            </Accordion>
                          </a>
                        ) : (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={'https://news.google.com/search?q=cabo%20frio&hl=pt-BR&gl=BR&ceid=BR%3Apt-419'}
                            >
                              <Accordion
                                expanded={this.state.expanded === `panel${item}`}
                                onChange={this.handleChangePanel(`panel${item}`)}
                                style={styles.button}
                              //onClick={() => readFile()}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel${item}a-content`}
                                  id={`panel${item}a-header`}
                                >
                                  <Typography>
                                    {item}
                                  </Typography>
                                </AccordionSummary>
                              </Accordion>
                            </a>
                        ) : null}
                      </div>
                    ) : (
                      <Accordion
                        expanded={this.state.expanded === `panel${item}`}
                        onChange={this.handleChangePanel(`panel${item}`)}
                        style={styles.button}
                      //onClick={() => readFile()}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${item}a-content`}
                          id={`panel${item}a-header`}
                        >
                          <Typography>
                            {item}
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>

                          <div style={styles.directionRow}>
                            <TextField
                              style={{ margin: 10 }}
                              id={`keyword${item}`}
                              name={`keyword${item}`}
                              label="Buscar por palavra chave"
                              variant="outlined"
                            />

                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginTop: 12, marginLeft: 10, height: 50, textTransform: 'none' }}
                              onClick={() => {
                                if (this.state.selected_city === 'paulo') {
                                  this.setState({ iframeUrl: `${apiMunicipios}/readfilepaulo/${item},${document.getElementById('keyword' + item).value}` });
                                } else {
                                  if (this.state.selected_city === 'cabo') {
                                    this.setState({ iframeUrl: `${apiMunicipios}/readfilecabo/${item},${document.getElementById('keyword' + item).value}` });
                                  } else {
                                    this.setState({ iframeUrl: `${apiMunicipios}/readfile/${item},${document.getElementById('keyword' + item).value}` });
                                  }
                                }
                              }}
                            >
                              Buscar
                            </Button>
                          </div>

                        </AccordionDetails>

                      </Accordion>
                    )}
                  </div>
                )}
                <TextField
                  style={{ margin: 20 }}
                  id="pref"
                  name="pref"
                  label="Buscar por palavra chave"
                  variant="outlined"
                />

                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: 30, marginLeft: 20, textTransform: 'none', }}
                  onClick={() => {
                    if (this.state.selected_city === 'paulo') {
                      this.setState({ iframeUrl: `${apiMunicipios}/readfilepaulo/${document.getElementById('pref').value}` });
                    } else {
                      if (this.state.selected_city === 'cabo') {
                        this.setState({ iframeUrl: `${apiMunicipios}/readfilecabo/${document.getElementById('pref').value}` });
                      } else {
                        this.setState({ iframeUrl: `${apiMunicipios}/readfile/${document.getElementById('pref').value}` });
                      }
                    }
                  }}
                >
                  Buscar
                </Button>
              </Box>

              <Box
                sx={{
                  width: '60%',
                  height: window.innerHeight,
                  backgroundColor: '#323232',
                  marginBottom: 10
                }}
              >
                {this.state.iframeUrl ? (
                  <iframe src={this.state.iframeUrl} title="Processos" style={styles.iframe} />
                ) : null}
              </Box>
            </div>
          ) : (
            <div style={styles.directionColumn}>
              <Box
                sx={{
                  width: '50%',
                  height: '50%',
                  backgroundColor: '#454545',
                }}
              >
                {anotherItems.map((item) =>
                  <div>
                    {item === 'Notícias' ? (
                      <div>
                        {this.state.selected_city === 'rios' ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://news.google.com/search?q=tr%C3%AAs%20rios&hl=pt-BR&gl=BR&ceid=BR%3Apt-419'}
                          >
                            <Accordion
                              expanded={this.state.expanded === `panel${item}`}
                              onChange={this.handleChangePanel(`panel${item}`)}
                              style={styles.button}
                            //onClick={() => readFile()}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${item}a-content`}
                                id={`panel${item}a-header`}
                              >
                                <Typography>
                                  {item}
                                </Typography>
                              </AccordionSummary>
                            </Accordion>
                          </a>
                        ) ? this.state.selected_city === 'paulo' (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://news.google.com/search?q=paulo%20de%20frontin&hl=pt-BR&gl=BR&ceid=BR%3Apt-419'}
                          >
                            <Accordion
                              expanded={this.state.expanded === `panel${item}`}
                              onChange={this.handleChangePanel(`panel${item}`)}
                              style={styles.button}
                            //onClick={() => readFile()}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${item}a-content`}
                                id={`panel${item}a-header`}
                              >
                                <Typography>
                                  {item}
                                </Typography>
                              </AccordionSummary>
                            </Accordion>
                          </a>
                        ) : (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={'https://news.google.com/search?q=cabo%20frio&hl=pt-BR&gl=BR&ceid=BR%3Apt-419'}
                            >
                              <Accordion
                                expanded={this.state.expanded === `panel${item}`}
                                onChange={this.handleChangePanel(`panel${item}`)}
                                style={styles.button}
                              //onClick={() => readFile()}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`panel${item}a-content`}
                                  id={`panel${item}a-header`}
                                >
                                  <Typography>
                                    {item}
                                  </Typography>
                                </AccordionSummary>
                              </Accordion>
                            </a>
                        ) : null}
                      </div>
                    ) : (
                      <Accordion
                        expanded={this.state.expanded === `panel${item}`}
                        onChange={this.handleChangePanel(`panel${item}`)}
                        style={styles.button}
                      //onClick={() => readFile()}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${item}a-content`}
                          id={`panel${item}a-header`}
                        >
                          <Typography>
                            {item}
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>

                          <div style={styles.directionRow}>
                            <TextField
                              style={{ margin: 10 }}
                              id={`keyword${item}`}
                              name={`keyword${item}`}
                              label="Buscar por palavra chave"
                              variant="outlined"
                            />

                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginTop: 12, marginLeft: 10, height: 50, textTransform: 'none' }}
                              onClick={() => {
                                if (this.state.selected_city === 'paulo') {
                                  this.setState({ iframeUrl: `${apiMunicipios}/readfilepaulo/${item},${document.getElementById('keyword' + item).value}` });
                                } else {
                                  if (this.state.selected_city === 'cabo') {
                                    this.setState({ iframeUrl: `${apiMunicipios}/readfilecabo/${item},${document.getElementById('keyword' + item).value}` });
                                  } else {
                                    this.setState({ iframeUrl: `${apiMunicipios}/readfile/${item},${document.getElementById('keyword' + item).value}` });
                                  }
                                }
                              }}
                            >
                              Buscar
                            </Button>
                          </div>

                        </AccordionDetails>

                      </Accordion>
                    )}
                  </div>
                )}
                <TextField
                  style={{ margin: 10, width: 250 }}
                  id="pref"
                  name="pref"
                  label="Buscar por palavra chave"
                  variant="outlined"
                />

                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: 10, textTransform: 'none', }}
                  onClick={() => {
                    if (this.state.selected_city === 'paulo') {
                      this.setState({ iframeUrl: `${apiMunicipios}/readfilepaulo/${document.getElementById('pref').value}` });
                    } else {
                      if (this.state.selected_city === 'cabo') {
                        this.setState({ iframeUrl: `${apiMunicipios}/readfilecabo/${document.getElementById('pref').value}` });
                      } else {
                        this.setState({ iframeUrl: `${apiMunicipios}/readfile/${document.getElementById('pref').value}` });
                      }
                    }
                  }}
                >
                  Buscar
                </Button>
              </Box>

              <Box
                sx={{
                  width: '94.5%',
                  height: window.innerHeight - 300,
                  backgroundColor: '#323232',
                  margin: 10,
                  marginBottom: 10,
                }}
              >
                {this.state.iframeUrl ? (
                  <iframe src={this.state.iframeUrl} title="Processos" style={styles.iframe} />
                ) : null}
              </Box>
            </div>
          )}

        </Box>

        <FooterHome />

      </div>

    );

  }

}

let styles = {

  button: {
    marginLeft: -2,
    borderTop: '1px solid rgba(254, 222, 90, 1)',
    borderBottom: '1px solid rgba(254, 222, 90, 1)',
    width: 360,
    backgroundColor: '#343434',
  },

  directionRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },

  directionColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },

  iframe: {
    borderWidth: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#777777',
  },

}