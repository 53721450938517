import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import InputMask from 'react-input-mask';

import backgroundVideo from '../assets/background.mp4';
import logo from '../assets/kprocessos_fav_logo.png';

import Footer from '../components/Footer';

export default class Login extends Component {
  
  state = {
    option: 'cnpj',
    type: 'E-mail',
    mask: '',
    login: '',
    email: '',
    password: '',
    city: 'tresrios',
    secretary: 'mendes',
    emailView: true,

    open_error: false,
    msg: '',
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.option === 'cnpj' || this.state.option === 'admin') {
      await this.setState({ login: this.state.email });
    }

    const data = new FormData();

    data.append('login', this.state.login);

    /*if ( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.props.history.push('/report');*/
    //} else {

      try {
        let resp = await api.get('/checkuser', {
          params: {
            cpf_cnpj: this.state.login,
            city: this.state.city,
            password: this.state.password,
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {
              if (this.state.option === 'cpf') {
                this.props.history.push({
                  pathname: '/citzenhome',
                  state: {
                    fields: {
                      user_id: JSON.stringify(resp.data[0].id),
                      login: data.get('login'),
                      option: this.state.option,
                      selected_city: this.state.city
                    }
                  }
                });
              } else if ((this.state.option === 'cnpj') && (this.state.city === 'rio')) {
                this.props.history.push({
                  pathname: '/homerio',
                  state: {
                    fields: {
                      login: data.get('login'),
                      option: this.state.option,
                      selected_city: this.state.city
                    }
                  }
                });
              } else {
                if ((this.state.option === 'cnpj') && (this.state.city === 'mendes')) {
                  this.props.history.push({
                    pathname: '/home',
                    state: {
                      fields: {
                        login: data.get('login'),
                        option: this.state.option,
                        selected_city: this.state.city
                      }
                    }
                  });
                } else {
                  if ((this.state.option === 'cnpj') && (this.state.city === 'paulo')) {
                    this.props.history.push({
                      pathname: '/homepaulo',
                      state: {
                        fields: {
                          user_id: JSON.stringify(resp.data[0].id),
                            login: data.get('login'),
                            option: this.state.option,
                            selected_city: this.state.city,
                            name: JSON.stringify(resp.data[0].name),
                            secretary: JSON.stringify(resp.data[0].secretary),
                            secretary_id: JSON.stringify(resp.data[0].secretary_id)
                        }
                      }
                    });
                  } else {
                    if ((this.state.option === 'cnpj') && (this.state.city === 'tresrios')) {
                      this.props.history.push({
                        pathname: '/hometresrios',
                        state: {
                          fields: {
                            user_id: JSON.stringify(resp.data[0].id),
                            login: data.get('login'),
                            option: this.state.option,
                            selected_city: this.state.city,
                            name: JSON.stringify(resp.data[0].name),
                            secretary: JSON.stringify(resp.data[0].secretary),
                            secretary_id: JSON.stringify(resp.data[0].secretary_id)
                          }
                        }
                      });
                    } else {
                      if ((this.state.option === 'cnpj') && (this.state.city === 'cabo')) {
                        this.props.history.push({
                          pathname: '/homecabo',
                          state: {
                            fields: {
                              user_id: JSON.stringify(resp.data[0].id),
                                login: data.get('login'),
                                option: this.state.option,
                                selected_city: this.state.city,
                                name: JSON.stringify(resp.data[0].name),
                                secretary: JSON.stringify(resp.data[0].secretary),
                                secretary_id: JSON.stringify(resp.data[0].secretary_id)
                            }
                          }
                        });
                      } else {
                        if ((this.state.option === 'admin')) {
                          this.props.history.push({
                            pathname: '/homeadmin',
                            state: {
                              fields: {
                                user_id: JSON.stringify(resp.data[0].id),
                                login: data.get('login'),
                                option: this.state.option,
                                selected_city: this.state.city,
                                name: JSON.stringify(resp.data[0].name),
                                secretary: JSON.stringify(resp.data[0].secretary),
                                secretary_id: JSON.stringify(resp.data[0].secretary_id)
                              }
                            }
                          });
                        } 
                      }
                    }
                  }
                }
              }

            } else {
              let msg = JSON.stringify(resp.data);
              const finalmsg = msg.toString().split('"').join('');
              this.setState({ msg: finalmsg });
              this.setState({ open_error: true });
            }
          }
        }
      } catch (err) {
        console.error(err);
        this.setState({ msg: 'CNPJ/CPF incorreto. ' + err });
        this.setState({ open_error: true });
      }

    //}

  }

  handleMask = async e => {
    await this.setState({ [e.target.name]: e.target.value });

    if (this.state.option == "cnpj") {
      this.setState({ emailView: true });
      this.setState({ mask: '' });
      this.setState({ type: 'E-mail' });
    }
    if (this.state.option == "cpf") {
      this.setState({ emailView: false });
      this.setState({ mask: '999.999.999-99' });
      this.setState({ type: 'CPF' });
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <html style={styles.html}>
        <video autoPlay muted loop id="myVideo" style={styles.video}>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        <div style={styles.divForm}>
          <form style={styles.form} id="new_login" onSubmit={this.handleSubmit}>

            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div style={styles.paper}>
                <img src={logo} style={styles.avatar} />
                <Typography component="h1" variant="h5">
                  Login
                </Typography>

                {this.state.open_error ? (
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.setState({ open_error: false });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {this.state.msg}
                  </Alert>
                ) : null}

                <div style={styles.form}>

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Opção</FormLabel>
                      <RadioGroup
                        aria-label="option1"
                        name="option"
                        value={this.state.option}
                        onChange={this.handleMask}
                        style={{ display: 'flex', flexDirection: 'row', width: '102%' }}
                      >
                        <FormControlLabel value="admin" control={<Radio />} label="Administrador" />
                        <FormControlLabel value="cnpj" control={<Radio />} label="Analista" />
                        <FormControlLabel value="cpf" control={<Radio />} label="Requerente" />
                      </RadioGroup>
                    </FormControl>

                    <FormControl variant="outlined" style={{ width: 350 }}>
                      <InputLabel htmlFor="outlined-city-native-simple">
                        Município *
                      </InputLabel>
                      <Select
                        native
                        required
                        value={this.state.city}
                        onChange={this.handleChange}
                        label="Município"
                        inputProps={{
                          name: 'city',
                          id: 'outlined-city-native-simple',
                        }}
                      >
                        <option value="cabo">Cabo Frio</option>
                        <option value="rios">Três Rios</option>
                        <option value="paulo">Paulo de Frontin</option>
                        {/*<option value="direitos_humanos">Direitos Humanos</option>
                        <option value="meio_ambiente">Meio Ambiente</option>
                        <option value="saude">Saúde</option>*/}
                      </Select>
                    </FormControl>
                  </div>

                  {this.state.emailView ? (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="E-mail"
                      name="email"
                      autoComplete="email"
                      InputProps={{ style: styles.input }}
                      onChange={this.handleChange}
                      value={this.state.email}
                    />
                  ) : (
                      <InputMask
                        {...this.props.value}
                        mask={this.state.mask}
                        fullWidth
                        maskChar=" "
                        required
                        name="login"
                        id="login"
                        type="text"
                        onChange={this.handleChange}
                        value={this.state.login}
                      >
                        {(inputProps) =>
                          <TextField
                            {...inputProps}
                            variant="outlined"
                            margin="normal"
                            label={this.state.type}
                          />
                        }
                      </InputMask>
                  )}

                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    InputProps={{ style: styles.input }}
                    onChange={this.handleChange}
                    value={this.state.password}
                  />
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Lembrar de mim"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={styles.submit}
                    startIcon={<ExitToAppIcon />}
                  >
                    Entrar
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2" style={styles.link}>
                        Esqueceu a senha?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href={`${apiString}/register`} variant="body2" style={styles.link}>
                        {"Criar nova conta"}
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Container>
            <Footer />
          </form>
        </div>
      </html>
    );
  }
}

let styles = {
  html: {
    backgroundColor: '#343434',
    backgroundSize: "100% 100%",
    height: window.innerHeight,
    width: window.innerWidth,
  },
  video: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  },
  paper: {
    padding: 30,
    marginTop: -20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#343434',
    position: 'relative',
  },
  avatar: {
    alignSelf: 'center',
    width: 100,
    height: 100,
    marginBottom: 10,
    marginTop: -10,
    marginRight: 30,
  },
  divForm: {
    alignSelf: 'center',
  },
  form: {
    width: '99%', // Fix IE 11 issue.
    paddingTop: 50,
  },
  input: {
    color: '#fff',
  },
  submit: {
    marginTop: 10,
    marginBottom: 10,
    textTransform: 'none',
    backgroundColor: '#fede5a',
  },
  link: {
    color: '#fede5a',
  },
};