import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';

import Header from '../components/AdminHeader';
import '../pages/styles/main.scss';
import Performance from '../components/dashboards/Performance';
import PercentPie from '../components/dashboards/PercentPie';
import LineChart from '../components/dashboards/LineChart';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FooterHome from '../components/FooterHome';

export default class HomeAdmin extends Component {

  state = {
    user_id: '',
    login: '',
    option: '',
    selected_city: '',
    city: '',
    name: '',
    secretary: '',
    secretary_id: '',

    row: true,
    latitude: null,
    longitude: null,
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ login: this.props.location.state.fields.login });
      await this.setState({ option: this.props.location.state.fields.option });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
      await this.setState({ name: this.props.location.state.fields.name.split('"').join('') });
      await this.setState({ secretary: this.props.location.state.fields.secretary.split('"').join('') });
      await this.setState({ secretary_id: this.props.location.state.fields.secretary_id });
      await this.setState({ user_id: this.props.location.state.fields.user_id });
    } else {
      alert("Não foi possível buscar os dados de login.");
    }

    if (this.state.selected_city === 'paulo') {
      this.setState({ city: 'Paulo de Frontin' });
    } else {
      if (this.state.selected_city === 'cabo') {
        this.setState({ city: 'Cabo Frio' });
      } else {
        if (this.state.selected_city === 'mendes') {
          this.setState({ city: 'Mendes' });
        } else {
          if (this.state.selected_city === 'rios') {
            this.setState({ city: 'Três Rios' });
          } else {
            this.setState({ city: 'Rio de Janeiro' });
          }
        }
      }
    }

    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.setState({ row: false });
    } else {
      this.setState({ row: true });
    }

    await this.setState({ latitude: -22.549731 });
    await this.setState({ longitude: -43.677680 });

  }

  render() {

    return(

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        margin: -10,
      }}
      >

        <Header
          history={this.props.history}
          login={this.state.login}
          option={this.state.option}
          selected_city={this.state.selected_city}
          name={this.state.name}
          secretary={this.state.secretary}
          secretary_id={this.state.secretary_id}
          user_id={this.state.user_id}
          {...this.props}
        />

        <Box sx={{
          backgroundColor: '#454545',
        }}
        >

          {this.state.row ? (

            <div style={styles.directionRow}>
              <Box
                sx={{
                  width: '100%',
                  height: window.innerHeight,
                  backgroundColor: '#454545',
                }}
              >

                <Typography style={styles.text}>
                  Bem vindo {this.state.name} - Administrador - {this.state.city}
                </Typography>

                <div style={styles.directionRow}>
                  <Performance
                    style={{ margin: 7 }}
                    typeNames={this.state.type_names}
                    typeLength={this.state.typeLength}
                  />
                  <PercentPie
                    dataLength={this.state.dataLength}
                    style={{ margin: 7 }}
                  />
                  <LineChart dates={this.state.dates} objectsLength={this.state.objectsLength} style={{ marginLeft: 7 }} />
                </div>

              </Box>
            </div>

          ) : (

            <div style={styles.directionRow}>
              <Box
                sx={{
                  width: '100%',
                  height: window.innerHeight,
                  backgroundColor: '#454545',
                }}
              >

                <Typography style={styles.text}>
                  Bem vindo {this.state.name} - Administrador - {this.state.city}
                </Typography>

                <div style={styles.directionColumn}>
                  <Performance
                    style={{ marginBottom: 10 }}
                    typeNames={this.state.type_names}
                    typeLength={this.state.typeLength}
                  />
                  <PercentPie
                    dataLength={this.state.dataLength}
                    style={{ marginBottom: 10 }}
                  />
                  <LineChart dates={this.state.dates} objectsLength={this.state.objectsLength} style={{ marginBottom: 10 }} />
                </div>

              </Box>
            </div>

          )}

        </Box>

      </div>
      
    );

  }

}

let styles = {

  directionRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },

  directionColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },

  text: {
    margin: 20,
    color: '#fff'
  },

}