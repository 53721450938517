import React, { Component } from 'react';
import api from '../services/api';
import apiString from '../services/apiString';
import apiStringServer from '../services/apiStringServer';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Send from '@material-ui/icons/Send';

export default class Process extends Component {

  state = {
    user: '',
    user_id: '',
    process_id: '',
    process: [],
    process_files: [],
    progress: [],
    observations: '',

    selected_city: ''
  }

  async componentDidMount() {

    if (this.props.location.state) {
      await this.setState({ process_id: this.props.location.state.fields.process_id });
      await this.setState({ user: this.props.location.state.fields.user });
      await this.setState({ selected_city: this.props.location.state.fields.selected_city });
    } else {
      const idx = document.URL.indexOf('?');

      var params = [];
      if (idx !== -1) {
        var pairs = document.URL.substring(idx + 1, document.URL.length).split('&');
        //alert(pairs);
        for (var i = 0; i < pairs.length; i++) {
          let nameVal = pairs[i].split(',');
          nameVal = pairs[i].replace('process_id=', '');
          nameVal = pairs[i].replace('user=', '');
          nameVal = pairs[i].replace('selected_city=', '');
          params.push(nameVal);
        }
      }

      await this.setState({ process_id: decodeURI(params[0]) });
      await this.setState({ user: decodeURI(params[1]) });
      await this.setState({ selected_city: decodeURI(params[2]) });
    }

    await this.setState({ process_id: this.state.process_id.replace('process_id=', '') });
    await this.setState({ user_id: this.state.user.replace('user=', '') });

    try {
      let resp = await api.get('/oneprocess', {
        params: {
          process_id: this.state.process_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('protocol')) {

            this.setState({ process: resp.data });

            if (this.state.user !== '' || this.state.user !== undefined) {
              await this.setState({ user_id: this.state.user });
            } else {
              await this.setState({ user_id: resp.data[0].user_id });
            }

          } else {
            let msg = JSON.stringify(resp.data);
            alert(msg);
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os dados do processo. ' + err);
    }

    try {
      let resp = await api.get('/processfiles', {
        params: {
          process_id: this.state.process_id,
          selected_city: this.state.selected_city
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('file')) {

            this.setState({ process_files: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            alert(msg);
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os arquvios do processo. ' + err);
    }

    try {
      let resp = await api.get('/progress', {
        params: {
          process_id: this.state.process_id,
          selected_city: this.state.selected_city,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('progress')) {
              
              this.setState({ progress: resp.data });

          } else {
            let msg = JSON.stringify(resp.data);
            //alert(msg);
          }
        }
      }
    } catch (err) {
      alert('Não foi possível buscar os dados do andamento do processo. ' + err);
    }

  }

  handleSend = async () => {
      
      if (this.state.observations === '') {
        alert('Preencha o campo de observações.');
      } else {
        try {
          let resp = await api.post('/progress', {
            user_id: this.state.user_id,
            process_id: this.state.process_id,
            selected_city: this.state.selected_city,
            observations: this.state.observations,
          });
          if (resp != null) {
            if (resp.data) {
              if (JSON.stringify(resp.data).includes('registred')) {
                this.componentDidMount();
              } else {
                let msg = JSON.stringify(resp.data);
                alert(msg);
              }
            }
          }
        } catch (err) {
          alert('Não foi possível enviar as observações. ' + err);
        }
      }
  }

  handleChange = async e => {
    await this.setState({ [e.target.name]: e.target.value });
  }

  render() {

    return(

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        margin: -10,
      }}
      >
        <Box sx={{
          backgroundColor: '#454545',
          height: window.innerHeight
        }}
        >
          <div style={styles.directionRow}>
            <Container maxWidth="lg" style={{ margin: 15, backgroundColor: '#343434' }}>
              {this.state.process.map((item) =>
                <Typography variant="h6" color="textSecondary" align="left">
                  <div>
                    Protocolo: {item.protocol}
                  </div>
                  <div>
                    {item.secretary_name}
                  </div>
                  <div>
                    Subcategoria: {item.subcategory_name}
                  </div>
                  <div>
                    Endereço: {item.street}, {item.number} - {item.complement ? item.complement : '(sem complemento)'}, 
                    {' '}{item.district}, {item.city} - {item.state}
                  </div>
                  <div>
                    Remetente: {item.user_name}
                  </div>
                  <div>
                    E-mail do remetente: {item.email}
                  </div>
                </Typography>
              )}
            </Container>

            {this.state.process_files ? (
              <Container maxWidth="lg" style={{ margin: 15, backgroundColor: '#343434' }}>
                <Typography variant="h6" color="textSecondary" align="left">
                  <div>
                    Arquivos
                  </div>
                </Typography>
                {this.state.process_files.map((item) =>
                  <Typography variant="h6" color="textSecondary" align="left">
                    <a target="_blank" rel="noreferrer" href={`${apiStringServer}/documents/${item.file}`}>
                      <Typography variant="subtitle1" color="textSecondary" align="left">
                        {item.file}
                      </Typography>
                    </a>
                  </Typography>
                )}
              </Container>
            ) : null}
          </div>

          <Container maxWidth="lg" style={{ margin: 15, backgroundColor: '#343434' }}>
            <Typography variant="h6" color="textSecondary" align="left">
              <div>
                Andamento
              </div>
            </Typography>
            <TextField
              id="observations"
              name="observations"
              label="Observações"
              multiline
              value={this.state.observations}
              style={{ margin: 10, width: '50%' }}
              variant="outlined"
              onChange={this.handleChange}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 20, textTransform: 'none' }}
              onClick={this.handleSend}
            >
              <Send />
            </Button>
            {this.state.progress.map((item) =>
              <Typography variant="subtitle1" color="textSecondary" align="left">
                {item.user_name}: {item.message}
              </Typography>
            )}
          </Container>
        </Box>
      </div>

    );

  }

}

let styles = {

  directionRow: { 
    display: 'flex', 
    flexDirection: 'row' 
  },

}